<template>
  <div
    id="app"
    class="flex flex-col h-full overflow-hidden"
    :class="pageClass"
  >
    <nprogress-container />
    <slot name="nav" />
    <slot />
   <OverlayContainer />
   <DriveModal />
    <!-- <ActionToast /> -->
    <!-- <AppLoader /> -->
    <RouterView :key="$route.path" />
  </div>
</template>

<script>

import { keyboardShortcuts } from '@mixins/keyboard-shortcuts'
import { appDarkMode } from '@mixins/app-dark-mode'
const AppLoader = () => import('@components/AppLoader')
// const ActionToast = () => import('@components/toast/ActionToast')
const OverlayContainer = () => import('@components/OverlayContainer')
const DriveModal = () => import('@components/DriveModal')
const NprogressContainer = () => import(/* webpackChunkName: 'nav' */'vue-nprogress/src/NprogressContainer')

export default {
  name: 'App',

  components: {
    AppLoader,
    // ActionToast,
    OverlayContainer,
    DriveModal,
    // NavBar,
    NprogressContainer
  },

  mixins: [keyboardShortcuts, appDarkMode],

  computed: {

    containerClass() {
      if (this.isPublic) return ''
      return 'site-grid'
    },

    pageClass() {
      if (this.$route.meta.pageClass) return this.$route.meta.pageClass
      return ''
    },

    isPublic() {
      return this.$route.meta.isPublic === true
    },

    navBarComponent() {
      if (this.isPublic) return 'nav-bar-public'
      return 'nav-bar'
    },

    isOnlyGuest() {
      if (this.user === null) return false
      return this.user.is_only_guest
    },

    user() {
      return this.$store.getters.user
    },

    teams() {
      return this.$store.getters['teams/teams']
    },

    navVisible() {
      if (this.$route.query.hideNav === 'y') return false
      return true
    },

    projectLoadingReadyState() {
      return this.$store.state.active.projectLoadingReadyState
    },

    showLoading() {
      return this.projectLoadingReadyState === 'loading'
    },

    routeMeta() {
      return this.$route
    }

  },

  watch: {
    teams() {
      this.registerGlobalWSCallbacks()
    }

  },

  created() {
    this.registerGlobalWSCallbacks()
  },

  methods: {

    noop() { },

    registerGlobalWSCallbacks() {
      // let that = this
      // this.teams.forEach(function (team) {
      //   window.Echo.private('team.' + team.id)
      //     .listen('.projectsUpdated', (e) => {
      //       that.$store.dispatch('projectsList/reload')
      //     })
      // })
    }

  }
}
</script>
