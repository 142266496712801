import { noopPromise } from '@utils/misc.js'

const state = {
  project: null
}

const actions = {
  setProjectLoading ({ commit, dispatch, state }) {
    commit('setProjectLoading')
    return noopPromise()
  },
  setProjectLoaded ({ commit, dispatch, state }) {
    commit('setProjectLoaded')
    return noopPromise()
  },
  setProjectLoadingError ({ commit, dispatch, state }) {
    commit('setProjectLoadingError')
    return noopPromise()
  }
}

const mutations = {

  setProjectLoading (state) {
    state.projectLoadingReadyState = 'loading'
  },

  setProjectLoaded (state) {
    state.projectLoadingReadyState = 'ready'
  },

  setProjectLoadingError (state) {
    state.projectLoadingReadyState = 'error'
    state.project = null
  }
}

const getters = {

}

export default {
  state,
  actions,
  mutations,
  getters
}
