import * as api from '@utils/api.js'
import {
  findInListByKey,
  updateInListByKey,
  findManyInListByKey
} from '@utils/misc.js'
import orderBy from 'lodash/orderBy'
import PrevizDirectUpload from '@/libs/direct-upload.js'

const DirectUpload = new PrevizDirectUpload()

const state = {
  active: null,

  folders: [],
  items: [],
  expanded: [],
  resources: [],

  filterItems: [],
  filterTerm: null,

  seen: [],
  expandedSeen: [],

  hasMore: false,
  totalRecords: null,
  loaded: false,
  loading: false,
  loadingError: false,

  nextPageLink: null,
  prevPageLink: null,

  uploading: [],

  // Loading flags
  isPrevizInDriveRequestPending: false
}

const getters = {
  everything(state) {
    return state.items
  },
  active(state) {
    return state.active ? state.active : ''
  },
  activeSet: (state) => (id) => {
    if (state.active === null) return null

    let active = findInListByKey(state.folders, 'id', state.active)

    if (active !== null) {
      if (active.project_id !== undefined && active.project_id === id)
        return active
    }
    return null
  },

  getTreeForFolderId: (state) => (id) => {
    let ret = []

    function getParent(parentId) {
      let item = findInListByKey(state.folders, 'id', parentId)
      if (item) {
        let arr = item

        ret.push(arr)
        if (arr.parent) {
          getParent(arr.parent)
        }
      }
    }

    let activeFolder = findInListByKey(state.folders, 'id', id)

    if (activeFolder) {
      ret.push(activeFolder)
      let parentId = activeFolder.parent
      if (parentId !== null) {
        getParent(parentId)
      }
    }

    return ret.reverse()
  },

  loadedSets(state) {
    let ret = []
    state.folders.forEach((set) => {
      ret.push({
        id: set.id,
        child_count: set.child_count,
        name: set.name,
        parent: set.parent,
        is_root: set.is_root
      })
    })
    return ret
  },
  rawData(state) {
    return state.folders
  },
  folderTree(state) {},
  rootFolder(state) {
    return state.folders.find(function (item) {
      return item.is_root === true
    })
  },
  assets(state) {
    if (state.active === null) return []
    let folder = state.active

    let items = state.items.filter(function (item) {
      return item.parent_id === folder
    })

    return items
  },
  hasMore(state) {
    return state.hasMore
  },
  totalRecords(state) {
    return state.totalRecords
  },
  nextPageLink(state) {
    return state.nextPageLink
  },
  prevPageLink(state) {
    return state.prevPageLink
  },
  loading(state) {
    return state.loading
  },
  loaded(state) {
    return state.loaded
  },
  loadingError(state) {
    return state.loadingError
  },
  loadedAll(state) {
    return state.loaded && state.hasMore === false
  },
  uploading(state) {
    return state.uploading
  },
  setAssetProperty(state, { asset, key, value }) {
    asset[key] = value
  },
  getResourcesById: (state) => (id) => {
    let base = state.resources.filter((row) => row.asset_id === id)

    // This deduplicates any duplicated keys in the resource array
    // which fixes a cache bug where older keys may still be in the
    // array for resources we already have updated values for
    let deduped = {}
    base.forEach((row) => {
      deduped[row.key] = row
    })

    let ret = []
    Object.keys(deduped).forEach((key) => {
      ret.push(deduped[key])
    })
    return ret
  },
  getAssetById: (state) => (id) => {
    let found = state.expanded.find((asset) => asset.id === id)

    if (found === undefined) return null
    return found
  },
  getAssetSimpleById: (state) => (id) => {
    let found = state.items.find((asset) => asset.id === id)

    if (found === undefined) return null
    return found
  },
  getAssetsByIds: (state) => (ids) => {
    let found = []

    ids.forEach((id) => {
      let item = findInListByKey(state.items, 'id', id)
      if (item !== null) {
        found.push(item)
      }
    })

    return found
  },

  getRecentAssetsByType: (state) => (id, type) => {
    let items = findManyInListByKey(state.items, 'project_id', id)

    items = findManyInListByKey(items, 'type', type)

    return orderBy(items, ['updated_at'], ['desc'])
  },

  getRecentAssetsByProject: (state) => (id) => {
    let list = findManyInListByKey(state.items, 'project_id', id)

    let sliced = orderBy(list, ['updated_at'], ['desc']).slice(0, 8)

    return sliced
  },
  getAssetsByDependsOn: (state) => (id, type) => {
    let list = findManyInListByKey(state.items, 'depends_on_id', id)

    if (type !== undefined) {
      list = findManyInListByKey(list, 'type', type)
    }

    return orderBy(list, ['created_at'], ['desc'])
  },

  getIsPrevizInDriveRequestPending: (state) => {
    return state.isPrevizInDriveRequestPending
  }
}

const mutations = {
  setLoading(state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded(state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError(state) {
    state.loading = false
    state.loadingError = true
  },
  addFolder(state, payload) {
    let folder = payload.folder

    let collection = 'folders'
    if (payload.collection !== undefined) {
      collection = payload.collection
    }
    state[collection] = updateInListByKey(
      state[collection],
      'id',
      folder.id,
      folder
    )
  },
  addItem(state, item) {
    state.items = updateInListByKey(state.items, 'id', item.id, item)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },
  addData(state, payload) {
    let data = payload.data
    let length = data.length

    for (let index = 0; index < length; index++) {
      let value = data[index].id
      state.items = updateInListByKey(state.items, 'id', value, data[index])
      if (!state.seen.includes(value)) {
        state.seen.push(value)
      }
    }
  },
  setHasMore(state, more) {
    state.hasMore = more
  },
  setTotalRecords(state, total) {
    state.totalRecords = total
  },
  setNextPageLink(state, url) {
    state.nextPageLink = url
  },
  setPrevPageLink(state, url) {
    state.prevPageLink = url
  },

  clear(state) {
    state.active = null
    state.folders = []
    state.items = []
    state.seen = []
    state.hasMore = false
    state.loaded = false
    state.loading = false
    state.loadingError = false
    state.uploading = []
  },

  setActive(state, group) {
    state.active = group
  },

  addUploadingMap(state, { uploadElement }) {
    state.uploading.push(uploadElement)
  },

  removeUploadingMap(state, { uploadElement }) {
    var index = state.uploading.findIndex((e) => e.id === uploadElement.id)
    state.uploading.splice(index, 1)
  },

  setUploadElementProgress(state, { uploadElement, progress }) {
    uploadElement.progress = progress
  },
  setUploadElementState(state, { uploadElement, uploadState }) {
    uploadElement.uploadState = uploadState
  },
  setUploadElementAbortUpload(state, { uploadElement, abortUpload }) {
    uploadElement.abortUpload = abortUpload
  },

  renameAsset(state, { name, asset }) {
    var item = state.items.find((e) => e.id === asset.id)
    if (item !== undefined) {
      item.name = name
    }

    // messy but quick
    var itemE = state.expanded.find((e) => e.id === asset.id)
    if (itemE !== undefined) {
      itemE.name = name
    }
  },

  moveAssets(state, { assets, folderId }) {
    assets.forEach((asset) => {
      var index = state.items.findIndex((e) => e.id === asset.id)
      let updatedAsset = state.items[index]
      updatedAsset.parent_id = folderId
      state.items.splice(index, 1, updatedAsset)
    })
  },

  deleteAssets(state, { assets }) {
    assets.forEach((asset) => {
      var index = state.items.findIndex((e) => e.id === asset.id)
      if (index > -1) state.items.splice(index, 1)

      // Also remove from seen
      var i = state.seen.findIndex((id) => id === asset.id)
      if (i > -1) state.seen.splice(i, 1)

      // Also remove from expanded
      var expanded = state.expanded.find((e) => e.id === asset.id)
      if (expanded !== undefined) expanded.status = 'deleted'
    })
  },

  addAsset(state, item) {
    state.expanded = updateInListByKey(state.expanded, 'id', item.id, item)
    if (!state.expandedSeen.includes(item.id)) {
      state.expandedSeen.push(item.id)
    }
  },

  addResource(state, { id, item }) {
    item.asset_id = id
    state.resources = updateInListByKey(state.resources, 'id', item.id, item)
  },

  updateAssetSettings(state, { id, path, value }) {
    let item = findInListByKey(state.expanded, 'id', id)
    if (item) {
      item.settings[path] = value
      state.expanded = updateInListByKey(state.expanded, 'id', item.id, item)
    }
  },

  setIsPrevizInDriveRequestPending(state, value) {
    state.isPrevizInDriveRequestPending = value
  }
}

const actions = {
  setActive({ commit }, { group }) {
    commit('setActive', group)
  },

  clear({ commit }) {
    commit('clear')
  },

  setLoading({ commit, state }) {
    commit('setLoading', state)
  },

  updateAssetFromData({ commit }, { item }) {
    commit('addAsset', item)
  },

  softLoad({ state, dispatch }, { project }) {
    if (state.loaded === true || state.loading === true) return
    dispatch('loadRootFolder', { project })
  },

  reload({ dispatch }, { project }) {
    dispatch('clear').then(() => {
      dispatch('loadRootFolder', { project })
    })
  },

  loadRecentItems({ commit, dispatch }, { project }) {
    // Base Api call
    let uri = 'api/assets-browser/recents'
    let options = { params: { project_id: project.id } }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', { data: response.data, shallow: false })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadRootFolder({ commit, dispatch }, { project }) {
    // Base Api call
    let uri = 'api/assets-browser'
    let options = { params: { project_id: project.id } }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturnFolder', { data: response.data, shallow: false })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadPrevizInDrive({ commit, dispatch }, { project }) {
    dispatch('setIsPrevizInDriveRequestPending', true)

    // Base Api call
    let uri = 'api/v1/assets-browser/search'
    let options = { params: { project_id: project.id, type: 'previz' } }
    commit('setLoading')
    return api
      .rawApiGetCall(uri, options)
      .then((response) => {
        dispatch('handleReturn', { data: response.data, shallow: false })
        dispatch('setIsPrevizInDriveRequestPending', false)
      })
      .catch(() => {
        commit('setLoadingError')
        dispatch('setIsPrevizInDriveRequestPending', false)
      })
  },

  newPreviz({ commit }, { project, parent, name }) {
    return api.newPreviz(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      return data
    })
  },

  newFolder({ commit }, { project, parent, name }) {
    return api.newFolder(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      return data
    })
  },

  newFolderAndMove({ commit, dispatch }, { project, parent, name, assets }) {
    return api.newFolder(project, parent, name).then((ret) => {
      let data = ret.data
      let payload = { data: [data] }
      commit('addData', payload)

      let id = payload.data[0].id
      dispatch('move', { assets, folderId: id })
    })
  },

  newSequenceItem({ commit }, { sceneId, name }) {
    return api
      .newSequenceItem(sceneId, name)
      .then((ret) => {
        let data = ret.data
        let payload = { data: [data] }
        commit('addData', payload)
        return data
      })
      .catch(() => {
        return false
      })
  },

  newAssetItem({ commit }, { project, parent, name, type }) {
    return api
      .newAssetItem(project, parent, name, type)
      .then((ret) => {
        let data = ret.data
        let payload = { data: [data] }
        commit('addData', payload)

        return data
      })
      .catch(() => {
        return false
      })
  },

  addResource({ commit }, { id, item }) {
    commit('addResource', { id: id, item: item })
  },

  loadAsset({ commit, state, dispatch }, { id, force }) {
    if (force === undefined) force = false

    // Early return check
    if (!force) {
      if (state.expandedSeen.includes(id)) {
        return state.expanded.find((asset) => asset.id === id)
      }
    }

    let uri = 'api/v1/assets/' + id

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data

        if (data.resources) {
          if (
            data.resources.data !== undefined &&
            data.resources.data.length > 0
          ) {
            data.resources.data.forEach((row) => {
              let resource = row.data
              commit('addResource', { id: data.id, item: resource })
            })
          }

          // remove resources from the return data to prevent mis-accessing it
          delete data.resources
        }

        commit('addAsset', data)

        let ancestorsLink = findInListByKey(
          response.data.links,
          'rel',
          'assets-browser.ancestors'
        )
        let group = data.id
        let parentId = data.parent_id
        if (ancestorsLink) {
          // dispatch('setActive', { group: parentId })
          dispatch('loadAncestorsIfNeeded', { group, parentId, ancestorsLink })
        }

        return data
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  updateFolder({ commit }, { folder }) {
    commit('addFolder', { folder })
  },

  loadChildSequences({ commit, dispatch }, { id }) {
    let uri = 'api/v1/assets-browser/' + id + '/sequences'

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let data = response.data.data

        data.forEach((row) => {
          let sequence = row.data
          commit('addItem', sequence)
        })
        return data
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadFolder({ commit, dispatch }, { folderId, shallow }) {
    if (shallow === undefined) shallow = false

    let uri = 'api/assets-browser/' + folderId

    commit('setLoading')
    return api
      .rawApiGetCall(uri)
      .then((response) => {
        dispatch('handleReturnFolder', {
          data: response.data,
          shallow: shallow
        })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadNext({ dispatch, state }, { group }) {
    let uri = state.nextPageLink
    return api.rawApiGetCall(uri).then((response) => {
      dispatch('handleReturn', { data: response.data, group })
    })
  },

  handleReturnFolder({ commit, dispatch }, { data, shallow, collection }) {
    let payload = {
      folder: data.data
    }
    let group = data.data.id
    let parentId = data.data.parent

    if (shallow !== true) {
      dispatch('setActive', { group: group })
    }

    if (collection !== undefined) {
      payload.collection = collection
    }
    commit('addFolder', payload)

    let itemsLink = findInListByKey(
      data.links,
      'rel',
      'assets-browser.children'
    )
    if (itemsLink && shallow !== true) {
      commit('setNextPageLink', itemsLink.url)
      dispatch('loadNext', { group: group })
    }

    let ancestorsLink = findInListByKey(
      data.links,
      'rel',
      'assets-browser.ancestors'
    )
    if (ancestorsLink && shallow !== true) {
      dispatch('loadAncestorsIfNeeded', { group, parentId, ancestorsLink })
    }
  },

  loadAncestorsIfNeeded(
    { state, commit, dispatch },
    { group, parentId, ancestorsLink }
  ) {
    // Does this parent exist in our tree?
    let parent = findInListByKey(state.folders, 'id', parentId)
    if (parent) return

    // we don't have this parent, load all the folder's ancestors
    commit('setLoading')
    let url = ancestorsLink.url
    return api
      .rawApiGetCall(url)
      .then((response) => {
        response.data.data.forEach((row) => {
          dispatch('handleReturnFolder', { data: row, shallow: true })
        })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  handleReturn({ commit, dispatch }, { data, group }) {
    let dataCleaned = []
    data.data.forEach((row) => {
      dataCleaned.push(row.data)
    })

    let hasMore = false
    data.pagination.links.forEach((row) => {
      if (row.rel === 'pagination.next') {
        commit('setNextPageLink', row.url)
        hasMore = true
      }
      if (row.rel === 'pagination.previous') {
        commit('setPrevPageLink', row.url)
      }
    })

    let totalRecords = data.pagination.total_count

    commit('setHasMore', hasMore)
    let payload = { data: dataCleaned }
    commit('addData', payload)
    commit('setTotalRecords', totalRecords)

    if (hasMore) {
      dispatch('loadNext', { group: group })
    } else {
      commit('setLoaded')
    }
  },

  /**
   * "Cross-loads" an asset from Dropbox.
   *
   * @param commit
   * @param project
   * @param file
   * @return {Promise.<TResult>|*}
   */
  // uploadFromDropbox ({ commit, dispatch }, { project, file }) {
  //   let uploadElement = {
  //     id: file.link,
  //     file: file,
  //     uploadState: 'uploading',
  //     progress: 0,
  //     abortUpload: null,
  //     clearAfterError: () => {
  //       commit('removeUploadingMap', { uploadElement, removeAll: true })
  //     }
  //   }

  //   commit('addUploadingMap', { uploadElement })

  //   return api
  //     .uploadAssetFromDropbox(project, file.link)
  //     .then(assetData => {
  //       commit('removeUploadingMap', { uploadElement })
  //       return commit('addItem', assetData).then(asset => asset)
  //     })
  //     .catch(error => {
  //       if (uploadElement.uploadState === 'uploading') {
  //         commit('setUploadElementState', { uploadElement, uploadState: 'error' })
  //       }
  //       return error
  //     })
  // },

  /**
   * Uploads an asset directly
   *
   * If the asset is a supported "quick" asset - ie. image
   * will split it into 2 operations, first creating a stub
   * upload, to allow early preview, and a secondary real upload
   *
   */
  upload({ dispatch, commit }, { project, file, asset, parentId, extra }) {
    let uploadElement = {
      id:
        file.lastModified +
        ':' +
        file.name +
        ':' +
        file.size +
        ':' +
        Math.floor(Math.random() * 1000),
      name: file.name.split('.').slice(0, -1).join('.'),
      uploadState: 'uploading',
      progress: 0,
      file: file,
      abortUpload: null,
      clearAfterError: () => {
        commit('removeUploadingMap', { uploadElement })
      }
    }
    commit('addUploadingMap', { uploadElement })

    return new Promise((resolve, reject) => {
      let options = {
        progress: (progress) => {
          commit('setUploadElementProgress', { uploadElement, progress })
        }
      }

      DirectUpload.store(file, options)
        .then((response) => {
          let type = 'file'
          if (file.type.includes('image/')) type = 'image'
          else if (file.type.includes('video/')) type = 'video'
          else if (response.extension === 'mov') type = 'video'

          let data = {
            parent_id: parentId,
            project_id: project.id,
            type: type,
            uuid: response.uuid,
            key: response.key,
            name: file.name
          }

          return api
            .newAssetFromDirectUpload(data)
            .then((response) => {
              commit('setUploadElementState', {
                uploadElement,
                uploadState: 'complete'
              })
              commit('removeUploadingMap', { uploadElement })
              commit('addItem', response.data)

              return response
            })
            .catch((err) => {
              return err
            })
        })
        .catch((err) => {
          console.error('Direct upload rejected', err)
          reject(err)
        })
    })
  },

  /**
   * Updates the asset resources endpoint after a direct upload completion
   *
   */

  // eslint-disable-next-line no-empty-pattern
  updateResources({}, data) {
    return api
      .updateAssetResource(data)
      .then((response) => {
        return response
      })
      .catch((err) => {
        return err
      })
  },

  // eslint-disable-next-line no-empty-pattern
  updateResourceAssociation({}, data) {
    return api
      .updateAssetResourceAssociation(data)
      .then((response) => {
        return response
      })
      .catch((err) => {
        return err
      })
  },

  saveVersionWithNewData({ dispatch }, { asset, data }) {
    return api.saveAssetVersionNewData(asset, data).then((returnData) => {
      return returnData
    })
  },

  updateVersionCoverImage({ dispatch }, data) {
    return api.updateVersionCoverImage(data).then((returnData) => {
      return returnData
    })
  },

  /**
   * Uploads an asset version directly
   */
  uploadVersion({ dispatch }, { file, asset, extra }) {
    return api.uploadAssetVersion(file, asset, extra).then((assetData) => {
      return assetData
      // return dispatch('addItem', { item: assetData }).then(asset => asset)
    })
  },

  updateAssetSettings({ commit }, { id, settings }) {
    // commit('updateAssetSettings', { id, settings })
    return api.updateAssetSettings(id, settings)
  },

  addItem({ commit }, { item }) {
    commit('addItem', item)
    return item
  },

  uploadAssetFull({ commit, dispatch }, { project, file, asset, extra }) {
    let uploadElement = {
      id:
        file.lastModified +
        ':' +
        file.name +
        ':' +
        file.size +
        ':' +
        Math.floor(Math.random() * 1000),
      name: file.name.split('.').slice(0, -1).join('.'),
      uploadState: 'uploading',
      progress: 0,
      file: file,
      abortUpload: null,
      clearAfterError: () => {
        commit('removeUploadingMap', { uploadElement })
      }
    }

    commit('addUploadingMap', { uploadElement })

    // This is a direct file upload
    // We can access the file immediately from the user's filesystem now that they've passed it to the browser
    // So we'll read it in using the FileReader api, and then (if it's an image) we can let the user
    // immediately use it, while it's still uploading in the background
    function readAndPreview(file) {
      // Only support images for now
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        var reader = new window.FileReader()

        reader.addEventListener(
          'load',
          function () {
            // let assetData = asset
            let assetData = {}
            assetData.id = asset.id
            assetData.project_id = asset.project_id
            assetData.parent_id = asset.parent_id
            assetData.name = file.name.split('.').slice(0, -1).join('.')
            assetData.size = file.size
            assetData.thumbnail = this.result
            assetData.thumbnail_tiny = this.result
            assetData.url = this.result
            assetData.status = 'ready'
            assetData.ready_state = 'ready'
            assetData.type = 'image'
            assetData.length = 10
            assetData.variant_urls = {
              medium: this.result
            }

            dispatch('addItem', { item: assetData })
          },
          false
        )
        reader.readAsDataURL(file)
      }
    }

    readAndPreview(file)

    let options = {
      before: (request) => {
        let abortUpload = () => {
          commit('setUploadElementState', {
            uploadElement,
            uploadState: 'aborted'
          })
          commit('removeUploadingMap', { uploadElement })
          request.abort()
        }
        commit('setUploadElementAbortUpload', { uploadElement, abortUpload })
      },
      progress: (event) => {
        let progress = event.loaded / event.total
        commit('setUploadElementProgress', { uploadElement, progress })
      }
    }

    return api
      .uploadAsset(project, file, options, asset, extra)
      .then((assetData) => {
        commit('setUploadElementState', {
          uploadElement,
          uploadState: 'complete'
        })
        commit('removeUploadingMap', { uploadElement })
        return dispatch('addItem', { item: assetData }).then((asset) => asset)
      })
      .catch((error) => {
        if (uploadElement.uploadState === 'uploading') {
          commit('setUploadElementState', {
            uploadElement,
            uploadState: 'error'
          })
        }
        return error
      })
  },

  update({ commit }, { asset, payload }) {
    return api
      .updateAsset(asset.id, payload)
      .then((response) => {
        let data = response.data
        commit('addAsset', data)
        return true
      })
      .catch(() => {
        return false
      })
  },

  rename({ commit }, { asset, name }) {
    return api
      .renameAsset(asset, name)
      .then((response) => {
        commit('renameAsset', { asset, name })
        return response
      })
      .catch(() => {
        // Not sure why this is returning false instead of the error,
        // but not going to change it atm in case it breaks something
        return false
      })
  },

  delete({ commit }, { assets }) {
    let originals = assets
    commit('deleteAssets', { assets })
    return api.deleteAssets(assets).catch((error) => {
      console.log(originals)
      console.log(error)
    })
  },

  move({ commit }, { assets, folderId }) {
    let valid = []
    // Double check the folderId !== the asset.id (ie. it's been dropped on itself)
    assets.forEach((asset) => {
      if (asset.id !== folderId) {
        valid.push(asset)
      }
    })
    if (valid.length > 0) {
      commit('moveAssets', { assets: valid, folderId })
      return api.moveAssets(valid, folderId).catch((error) => {
        console.log(error)
      })
    }
  },

  setIsPrevizInDriveRequestPending({ commit }, value) {
    commit('setIsPrevizInDriveRequestPending', value)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
