/*
  Project module
  ---
  This is the v2 of the 'project' module
  This will contain general project storage
*/

import * as api from '@utils/api.js'
// import filter from 'lodash/filter'
// import split from 'lodash/split'
// import forEach from 'lodash/forEach'
// import includes from 'lodash/includes'
// import union from 'lodash/union'
// import orderBy from 'lodash/orderBy'

const state = {
  data: null,

  loadedOn: null,
  loaded: false,
  loading: false,
  loadingError: false

}

const getters = {
  project (state) {
    return state.data
  },

  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  }
}

const mutations = {
  addData (state, data) {
    state.data = data
  },

  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
    state.loadedOn = Date.now()
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  setProjectCover (state, { id, cover }) {
    for (let index = 0; index < state.data.length; index++) {
      if (state.data[index].id === id) {
        state.data[index].cover = cover
      }
    }
  },

  clear (state) {
    state.data = []
    state.loaded = false
    state.loading = false
    state.loadingError = false
  }

}

const actions = {

  clear ({ commit }) {
    commit('clear')
  },

  loadProject ({ commit, dispatch }, { projectId }) {
    // Base Api call
    let uri = 'api/v1/projects/' + projectId
    commit('setLoading')
    return api.rawApiGetCall(uri)
      .then(response => {
        return dispatch('handleReturn', response.data.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  setLoading ({ commit, state }) {
    commit('setLoading', state)
  },

  handleReturn ({ commit, dispatch }, data) {
    commit('addData', data)
    return data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
