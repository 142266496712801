import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'
import sortBy from 'lodash/sortBy'

const state = {
  teams: [],
  teamsLoaded: false,
  teamsLoading: false,
  teamsLoadingError: false
}

const getters = {
  teams (state) {
    return sortBy(state.teams, ['team_order'])
  },

  members: (state) => (id) => {
    return []
  },

  getTeamById: (state) => (id) => {
    let found = state.teams.find(row => {
      return (row.id === id || row.slug === id)
    })

    if (found === undefined) return null
    return found
  },

  teamsLoading (state) {
    return state.teamsLoading
  },

  teamsLoadingError (state) {
    return state.teamsLoadingError
  },

  teamsLoaded (state) {
    return state.teamsLoaded
  },

  roles (state) {
    return state.teams.map(function (team) {
      return team.team_role
    })
  }
}

const actions = {

  reorder ({ commit, state }, { order }) {
    commit('setOrder', { order })

    return api.reorderTeams(order)
      .catch(error => {
        console.warn('Failed to persist team reorder', error)
        alert.error('There was a problem reordering teams')
        return null
      })
  },
  setTeams ({ commit, state }, { teams }) {
    commit('setTeams', { state, teams })
  },
  addTeam ({ commit }, team) {
    commit('addTeam', team)
  },
  addTeamMembers ({ commit }, members) {
    commit('addTeamMembers', members)
  },
  setTeamsLoading ({ commit }) {
    commit('setTeamsLoading')
  },
  setTeamsLoadingError ({ commit }) {
    commit('setTeamsLoadingError')
  },
  setTeamsLoaded ({ commit }) {
    commit('setTeamsLoaded')
  },
  removeTeam ({ commit }, { id }) {
    commit('removeTeam', id)
  },

  fetchTeamsSoft ({ dispatch, state }) {
    if (state.teamsLoaded !== true) dispatch('fetchTeams')
  },

  updateIcon ({ commit }, data) {
    return api.updateTeamIcon(data)
      .then(response => {
        commit('addTeam', response.data)
      })
  },

  updateTeamOnboardingState ({ commit }, { team, key, needsaction }) {
    commit('updateTeamOnboardingState', { team, key, needsaction })
    return api.updateTeamOnboardingState(team, key, needsaction)
  },

  updateColor ({ commit }, { team, color }) {
    commit('setTeamColor', { team, color })
    return api.updateTeamColor(team, color)
  },

  removeIcon ({ commit }, { id }) {
    return api.removeTeamIcon(id)
      .then(response => {
        commit('addTeam', response.data)
      })
  },

  fetchTeam ({ commit }, { id }) {
    return api.getTeam(id)
      .then(response => {
        commit('addTeam', response.data)
      })
  },

  fetchTeams ({ dispatch, commit }) {
    dispatch('setTeamsLoading')

    return api.fetchTeams()
      .then(response => {
        response.data.forEach(row => {
          commit('addTeam', row.data)
        })
        dispatch('setTeamsLoaded')
        return response.data
      })
      .catch(() => {
        dispatch('setTeamsLoadingError')
      })
  },

  createTeam ({ commit }, { name }) {
    return api.createTeam(name)
      .then(response => {
        commit('addTeam', response.data)
        return response.data
      })
  },

  fetchTeamMembers ({ commit }, { id }) {
    console.log('FETCH HERE..')
    // return api.getTeamMembersAccesses(id)
    //   .then(members => {
    //     console.log(members)
    //     // dispatch('setTeamLoaded')
    //     // commit('setTeamMembers', { teamId, members })
    //     return members
    //   })
    //   .catch(error => {
    //     // dispatch('setTeamLoadingError')
    //     console.error(error)
    //     return null
    //   })

    return true
    // console.log('FETCH TEAM MEMBERS - ', id)
    // return api.getTeamMembers(id)
    //   .then(response => {
    //     commit('addTeamMembers', response.data)
    //   })
  }
}

const mutations = {

  updateTeamOnboardingState (state, { team, key, needsaction }) {
    let found = state.teams.find(row => {
      return (row.id === team.id)
    })

    if (found !== undefined) {
      found[key] = needsaction
    }

    state.teams = updateInListByKey(state.teams, 'id', found.id, found)
  },

  setTeamColor (state, { team, color }) {
    let found = state.teams.find(row => {
      return (row.id === team.id)
    })

    if (found !== undefined) {
      found.cover_color = color
    }

    state.teams = updateInListByKey(state.teams, 'id', found.id, found)
  },

  setOrder (state, { order }) {
    let teams = state.teams
    let reordered = []

    order.forEach(key => {
      let item = teams.find(row => row.id === key)
      if (item !== undefined) {
        reordered.push(item)
      }
    })

    state.teams = reordered
  },

  setTeams (state, { teams }) {
    state.teams = teams
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoaded (state) {
    state.teamsLoaded = true
    state.teamsLoading = false
    state.teamsLoadingError = false
  },
  setTeamsLoading (state) {
    state.teamsLoaded = false
    state.teamsLoading = true
    state.teamsLoadingError = false
  },
  setTeamsLoadingError (state) {
    state.teamsLoaded = false
    state.teamsLoading = false
    state.teamsLoadingError = true
  },
  addTeam (state, team) {
    if (team.members !== undefined) {
      delete team.members
    }
    state.teams = updateInListByKey(state.teams, 'id', team.id, team)
  },
  removeTeam (state, id) {
    const index = state.teams.findIndex(function (item) {
      return item.id === id
    })

    state.teams.splice(index, 1)
  },
  addTeamMembers (state, members) {
    console.log('ADD TEAM MEMBERS - ', members)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
