/*
  Assets module
  ---
  This is the v2 of the 'assets' module
  This will contain general assets storage

*/
import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {

  items: [],
  seen: [],

  hasMore: false,
  totalRecords: null,
  loaded: false,
  loading: false,
  loadingError: false,

  nextPageLink: null,
  prevPageLink: null,
  currentPage: null,
  totalPages: null,
  itemsPerPage: null
}

const getters = {
  hasMore (state) {
    return state.hasMore
  },
  totalRecords (state) {
    return state.totalRecords
  },
  currentPage (state) {
    return state.currentPage
  },
  totalPages (state) {
    return state.totalPages
  },
  nextPageLink (state) {
    return state.nextPageLink
  },
  prevPageLink (state) {
    return state.prevPageLink
  },
  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  },
  loadedAll (state) {
    return state.loaded && (state.hasMore === false)
  },
  results (state) {
    return state.items
  }
}

const mutations = {
  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  addItem (state, item) {
    state.items = updateInListByKey(state.items, 'id', item.id, item)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },
  addData (state, payload) {
    let data = payload.data
    let length = data.length

    for (let index = 0; index < length; index++) {
      let value = data[index].id
      state.items = updateInListByKey(state.items, 'id', value, data[index])
      if (!state.seen.includes(value)) {
        state.seen.push(value)
      }
    }
  },
  setHasMore (state, more) {
    state.hasMore = more
  },
  setCurrentPage (state, current) {
    state.currentPage = current
  },
  setTotalPages (state, total) {
    state.totalPages = total
  },
  setTotalRecords (state, total) {
    state.totalRecords = total
  },
  setNextPageLink (state, url) {
    state.nextPageLink = url
  },
  setPrevPageLink (state, url) {
    state.prevPageLink = url
  },

  clear (state) {
    state.items = []
    state.seen = []
    state.hasMore = false
    state.loaded = false
    state.loading = false
    state.loadingError = false

    state.totalPages = null
    state.currentPage = null
    state.prevPageLink = null
    state.nextPageLink = null
  }

}

const actions = {

  setActive ({ commit }, { group }) {
    commit('setActive', group)
  },

  clear ({ commit }) {
    commit('clear')
  },

  setLoading ({ commit, state }) {
    commit('setLoading', state)
  },

  search ({ commit, dispatch }, { params }) {
    dispatch('clear')
    let uri = 'api/assets-browser/search'

    let payload = params

    commit('setLoading')
    return api.rawApiPostCall(uri, payload)
      .then(response => {
        dispatch('handleReturn', { data: response.data })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadPrev ({ commit, dispatch, state }) {
    let uri = state.prevPageLink
    commit('setLoading')
    return api.rawApiPostCall(uri)
      .then(response => {
        dispatch('clear')
        dispatch('handleReturn', { data: response.data })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadNext ({ commit, dispatch, state }) {
    let uri = state.nextPageLink
    commit('setLoading')
    return api.rawApiPostCall(uri)
      .then(response => {
        dispatch('clear')
        dispatch('handleReturn', { data: response.data })
      })
      .catch(() => {
        console.log('CATCH ERRor state.')
        commit('setLoadingError')
      })
  },

  handleReturn ({ commit, dispatch }, { data }) {
    let dataCleaned = []
    data.data.forEach(row => {
      dataCleaned.push(row.data)
    })

    let hasMore = false
    data.pagination.links.forEach(row => {
      if (row.rel === 'pagination.next') {
        commit('setNextPageLink', row.url)
        hasMore = true
      }
      if (row.rel === 'pagination.previous') {
        commit('setPrevPageLink', row.url)
      }
    })

    let totalRecords = data.pagination.total_count
    let currentPage = data.pagination.current_page
    let totalPages = data.pagination.total_pages

    commit('setHasMore', hasMore)
    let payload = { data: dataCleaned }
    commit('addData', payload)
    commit('setTotalRecords', totalRecords)
    commit('setCurrentPage', currentPage)
    commit('setTotalPages', totalPages)

    commit('setLoaded')
  },

  addItem ({ commit }, { item }) {
    commit('addItem', item)

    return item
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
