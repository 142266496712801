import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from './auth'

// import AuthRoutes from './routes/auth.js'
import PagesRoutes from './routes/pages.js'
import EditorRoutes from './routes/editor.js'
import AuthRoutes from './routes/auth.js'

// import SandboxRoutes from './routes/sandbox.js'
import TestingRoutes from './routes/testing.js'

// General Pages
import store from '@/store/index'

Vue.use(VueRouter)

const appRoutes = [
  ...PagesRoutes,
  ...EditorRoutes,
  ...TestingRoutes,
  ...AuthRoutes
  // ...MissingRoutes // Missing should always be last
]

let routes = appRoutes

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.uuid !== undefined && to.meta.canSetAsLastDrive === true) {
    store.dispatch('setLastDrive', {
      drive: to.params.uuid,
      path: to.fullPath
    })
  }

  if (to.params.uuid !== undefined && to.meta.canSetAsFirst === true) {
    store.dispatch('setFirst', {
      project: to.params.uuid,
      path: to.fullPath
    })
  }

  if (to.matched.some((record) => record.meta.isAuthenticated !== false)) {
    authGuard(to, from, next)
  } else {
    next()
  }
})

export default router
