import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],

  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {

  loaded (state) {
    return state.loaded
  },

  loading (state) {
    return state.loading
  },

  getDataById: (state) => (id) => {
    let found = state.data.find(item => item.id === id)
    if (found === undefined) return null
    return found.data
  }

}

const mutations = {

  setSequenceData (state, { id, data }) {
    let item = {
      id: id,
      data: data
    }

    state.data = updateInListByKey(state.data, 'id', item.id, item)
  },

  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },

  clear (state) {
    state.loaded = false
    state.loading = false
    state.loadingError = false

    state.data = []
  }

}

const actions = {
  loadFromUrl ({ commit, dispatch }, { id, url }) {
    commit('setLoading')
    api.rawApiGetCall(url)
      .then(response => {
        dispatch('handleReturn', { id: id, data: response.data })
      })
      .catch(() => {
        console.log('error ')
      })
  },

  handleReturn ({ commit }, { id, data }) {
    commit('clear')
    commit('setSequenceData', { id: id, data: data.data })
    commit('setLoaded')
  },

  saveExport ({ commit }, { data }) {
    let id = data.id
    let payload = JSON.stringify(data.data)
    // let payload = {
    //   timeline: JSON.stringify(data)
    // }

    return api.saveSequence(id, payload)
      .then(response => {
        // console.log('Response from sequence save : ', response)
        // dispatch('handleReturn', { data: response.data })
        return true
      })
      .catch(err => {
        console.log('ERROR RESPONSE', err)
        // commit('setLoadingError')
        return false
      })
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
