// import animation from 'animate.css'

require('./fontawesome-icons')
// require('./tooltips')
require('./modals')

// // Third party vue helper components
// require('./vue-multiselect')
// require('./vue-notifications')
require('./vue-toggle-button')
require('./vue-slider')
require('./vue-load-scripts')
