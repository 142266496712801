export const keyboardShortcuts = {
  mounted() {
    document.addEventListener('keyup', this.handleKeyboardEvents)
    this.listenForGlobals()
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyboardEvents)
  },

  methods: {
    handleKeyboardEvents(event) {
      let activeElementType = document.activeElement.nodeName

      // We only listen for input if the activeElement is NOT an input, or text area
      if (activeElementType === 'INPUT' || activeElementType === 'TEXTAREA') {
        // Only listen for specific inputs when within an input
        switch (event.code) {
          case 'Escape':
            this.$bus.$emit('keyboard:event:escape')
            break
        }
      } else {
        switch (event.code) {
          case 'Slash':
            this.$bus.$emit('keyboard:event:slash')
            break
          case 'Escape':
            this.$bus.$emit('keyboard:event:escape')
            break
        }
      }
    },

    listenForGlobals() {
      this.$bus.$on('keyboard:event:slash', this.toggleAppSidebar)
    },

    toggleAppSidebar() {
      this.$store.dispatch('preferences/toggleSidebarHidden')
    }
  }
}
