/* eslint-disable camelcase */
import Vue from 'vue'
import store from '@/store/index'
import VueResource from 'vue-resource'

Vue.use(VueResource)
Vue.http.options.root = process.env.VUE_APP_SERVER_API_BASE

/**
 * Load Vue HTTP Interceptors.
 */
Vue.http.interceptors.push((request, next) => {
  if (store.getters['auth/isAuthed']) {
    // request.headers.set('X-DisguiseCloud-Application', 'previz')
    request.headers.set(
      'Authorization',
      store.getters['auth/token_type'] +
        ' ' +
        store.getters['auth/access_token']
    )
    request.headers.set('Accept', 'application/json')
  }
  next()
})

export function request(method, urlTemplate, urlElements, payload, options) {
  let customActions = {
    patch: { method: 'PATCH' }
  }

  return Vue.resource(urlTemplate, {}, customActions, options)
    [method](urlElements, payload)
    .then((response) => {
      return response.data
    })
    .catch((response) => {
      return Promise.reject(response.data)
    })
}

export function rawApiGetCall(uri, options) {
  return Vue.http.get(uri, options)
}

export function rawApiPostCall(uri, payload) {
  return Vue.http.post(uri, payload)
}

export function rawApiDeleteCall(uri, options) {
  return Vue.http.delete(uri, options)
}

export function fetchTeams() {
  return request('get', 'api/teams?include=members')
}

export function fetchInvitation(invitation_id) {
  return request('get', 'api-v1/invitations/{invitation_id}', {
    invitation_id: invitation_id
  })
}
export function getTeam(teamId) {
  return request('get', 'api-v1/teams/{team_id}', {
    team_id: teamId
  })
}

export function getTeamMembersAccesses(teamId) {
  return request('get', 'api-v1/teams/{team_id}/members', {
    team_id: teamId
  })
}

export function refreshAuthToken(refresh_token) {
  return Vue.http.post('api/auth/refresh', {
    refresh_token: refresh_token
  })
}

// --------------------------------------------------------------------------------------------------
// ---------------------------------      USERS     ------------------------------------------------
// --------------------------------------------------------------------------------------------------
export function updateUser(userId, data) {
  return request(
    'patch',
    'api/v1/users/{id}',
    {
      id: userId
    },
    data
  )
}

export function loginUser(email, password) {
  return Vue.http.post('api/login', {
    email: email,
    password: password
  })
}
export function logoutUser() {
  return Vue.http.post('api/logout', {})
}

export function authQuickJoin(token) {
  return Vue.http.post('api/quickjoin', {
    token: token
  })
}

export function requestUserPasswordReset(email) {
  return Vue.http.post('api/password-reset-request', { email: email })
}

export function submitUserPasswordReset(
  token,
  email,
  password,
  passwordConfirmation
) {
  return request('save', 'api/password-reset-confirm', {
    token: token,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation
  })
}

export function requestUserMagicLinks(email) {
  return request('save', '/magic', { email: email })
}

export function registerUserFromInvitation(email, terms, token) {
  return request('save', '/register', {
    email: email,
    terms: terms,
    token: token
  })
}

export function confirmUserEmail(email) {
  return request('save', 'api-v1/settings/email/request', { email: email })
}

export function resolveEmailConfirmation(code, token) {
  return request('save', 'api-v1/settings/email/confirm', {
    code: code,
    token: token
  })
}

export function validateEmailForJoin(email) {
  return request('save', 'api-v1/join/validate-email', { email: email })
}

export function validateAccountForJoin(email, team, name, password) {
  return request('save', 'api-v1/join/validate-account', {
    email: email,
    team: team,
    name: name,
    password: password
  })
}

export function createAccount(
  email,
  team,
  firstName,
  lastName,
  password,
  plan,
  token
) {
  return request('save', 'api-v1/join/create-account', {
    email: email,
    first_name: firstName,
    last_name: lastName,
    password: password,
    plan: plan,
    team: team,
    token: token
  })
}
export function createAccountPaid(
  email,
  firstName,
  lastName,
  password,
  plan,
  stripeToken,
  period
) {
  return request('save', 'api-v1/join/create-account-paid', {
    email: email,
    first_name: firstName,
    last_name: lastName,
    password: password,
    plan: plan,
    stripe_token: stripeToken,
    period: period
  })
}

// export function createAccountPaid (email, team, name, plan, discountCode, stripeToken, period) {
//   return request('save', 'api-v1/join/create-account-paid', {
//     email: email,
//     team: team,
//     name: name,
//     plan: plan,
//     discountCode: discountCode,
//     stripe_token: stripeToken,
//     period: period
//   })
// }

// export function createAccountMember (team, plan, discountCode) {
//   return request('save', 'api-v1/join/create-account-member', {
//     team: team,
//     plan: plan,
//     discountCode: discountCode
//   })
// }

// export function createAccountPaidMember (team, plan, discountCode, stripeToken, period) {
//   return request('save', 'api-v1/join/create-account-paid-member', {
//     team: team,
//     plan: plan,
//     discountCode: discountCode,
//     stripe_token: stripeToken,
//     period: period
//   })
// }

export function updatePassword(
  currentPassword,
  newPassword,
  newPasswordConfirmation
) {
  return Vue.http.put('api-v1/settings/password', {
    current_password: currentPassword,
    password: newPassword,
    password_confirmation: newPasswordConfirmation
  })
}

export function updatePasswordShort(newPassword) {
  return Vue.http.put('api-v1/settings/password/short', {
    password: newPassword
  })
}

export function updateContact(first_name, last_name, email) {
  return Vue.http.put('api-v1/settings/contact', {
    first_name: first_name,
    last_name: last_name,
    email: email
  })
}

export function updateProfileSkip() {
  return Vue.http.post('api-v1/settings/contact/skip')
}

export function updateProfileOnboardingProjectComplete() {
  return Vue.http.post('api-v1/settings/contact/onboardingproject')
}

export function updateProfileNeverAsk() {
  return Vue.http.post('api-v1/settings/contact/neverask')
}

export function createTeam(name) {
  return request('save', 'api/teams', {}, { name: name })
}

export function closeTeam(id) {
  return request('delete', 'api/teams/{id}', {
    id: id
  })
}

export function login(email, password) {
  return Vue.http.post('/login', { email: email, password: password })
}

export function updateTeamName(team, name) {
  return Vue.http.post('api-v1/teams/' + team.slug, { name: name })
}

export function removeTeamMember(team, member) {
  return Vue.http.delete('api-v1/teams/' + team.slug + '/members/' + member.id)
}

export function disableTeamMember(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/members/' + member.id + '/disable'
  )
}

export function enableTeamMember(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/members/' + member.id + '/enable'
  )
}

export function promoteTeamGuest(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/guests/' + member.id + '/promote'
  )
}

export function removeTeamGuest(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/guests/' + member.id + '/remove'
  )
}

export function promoteTeamMember(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/members/' + member.id + '/promote'
  )
}

export function downgradeTeamMember(team, member) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/members/' + member.id + '/downgrade'
  )
}

export function updateTeamMemberAccess(
  team,
  memberId,
  accessAll,
  accessProjects
) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/members/' + memberId + '/access',
    { accessAll: accessAll, accessProjects: accessProjects }
  )
}
export function updateTeamGuestAccess(team, memberId, accessProjects) {
  return Vue.http.put(
    'api-v1/teams/' + team.slug + '/guests/' + memberId + '/access',
    { accessProjects: accessProjects }
  )
}

export function inviteTeamMember(email, team, projectIds) {
  return Vue.http.post('api-v1/invitations', {
    email: email,
    project_ids: projectIds,
    team_id: team.id,
    type: 'member'
  })
}

export function inviteTeamAdmin(email, team) {
  return Vue.http.post('api-v1/invitations', {
    email: email,
    team_id: team.id,
    type: 'admin'
  })
}

export function inviteTeamByEmail(email, team, role, message) {
  return Vue.http.post('api-v1/invitations', {
    email: email,
    team_id: team.id,
    type: role,
    message: message
  })
}

export function updatePhoto(data) {
  return request('save', 'api/profile/photo', {}, data)
}

export function updateProjectCover(data) {
  return request(
    'save',
    'api/projects/{id}/cover',
    {
      id: data.project_id
    },
    data
  )
}

export function removeProjectCover(id) {
  return request('delete', 'api/projects/{id}/cover', {
    id: id
  })
}

export function updateTeamIcon(data) {
  return request(
    'save',
    'api/teams/{id}/icon',
    {
      id: data.team_id
    },
    data
  )
}

export function removeTeamIcon(id) {
  return request('delete', 'api/teams/{id}/icon', {
    id: id
  })
}

export function updateTeamColor(team, color) {
  return request(
    'patch',
    'api/teams/{id}',
    { id: team.id },
    { cover_color: color }
  )
}

export function updateTeamOnboardingState(team, key, needsaction) {
  let payload = {}
  payload[key] = needsaction

  return request('patch', 'api/teams/{id}', { id: team.id }, payload)
}

export function fetchBillingPlans() {
  return request('get', 'api/billing-plans')
}

export function fetchBillingPlan(slug) {
  return request('get', 'api/billing-plans/{slug}', { slug: slug })
}

/**
 * Redeems the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export function redeemInvitation(invitation) {
  return Vue.http.post('api-v1/invitations/' + invitation.token + '/redeem')
}

/**
 * Rejects the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export function rejectInvitation(invitation) {
  return Vue.http.post('api-v1/invitations/' + invitation.token + '/reject')
}

/**
 * Re-sends the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export function resendInvitation(invitation) {
  return Vue.http.post('api-v1/invitations/' + invitation.token + '/resend')
}

/**
 * Revokes the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export function revokeInvitation(invitation) {
  return Vue.http.delete('api-v1/invitations/' + invitation.token)
}

export function fetchApiMember(member) {
  return Vue.http.get('api-v1/members/' + member.id)
}

export function getNotifications() {
  let r = this.request('get', 'api-v1/notifications')
  return r
}

export function markMessageAsRead(messageId) {
  return request(
    'patch',
    'api-v1/notifications/{message_id}',
    {
      message_id: messageId
    },
    {
      read: true
    }
  )
}

export function fetchIntentToUpdatePaymentMethod(data) {
  return request(
    'save',
    'api-v1/accounts/{account_id}/fetch-intent-to-update-payment-method',
    {
      account_id: data.accountId
    },
    data
  )
}

export function fetchBillingPortalSession(accountId) {
  return request(
    'save',
    'api-v1/accounts/{account_id}/fetch-billing-portal-session',
    {
      account_id: accountId
    },
    {}
  )
}

export function updatePaymentMethod(accountId, stripeToken) {
  return request(
    'save',
    'api-v1/accounts/{account_id}/payment-methods',
    {
      account_id: accountId
    },
    {
      stripe_token: stripeToken
    }
  )
}

export function getAccount(accountId) {
  return request('get', 'api/accounts/{account_id}', {
    account_id: accountId
  })
}

export function cancelAccount(accountId, comments) {
  return request(
    'delete',
    'api-v1/accounts/{account_id}',
    {
      account_id: accountId
    },
    {
      comments: comments
    }
  )
}

export function reactivateAccount(accountId) {
  return request('save', 'api-v1/accounts/{account_id}/reactivate', {
    account_id: accountId
  })
}

export function startSubscriptionEarly(accountId) {
  return request(
    'save',
    'api-v1/accounts/{account_id}/start-subscription-early',
    {
      account_id: accountId
    }
  )
}

export function getAccountInvoices(accountId) {
  return request('get', 'api-v1/accounts/{account_id}/invoices', {
    account_id: accountId
  })
}

export function updateAccountPlan(accountId, newPlanId, period, stripeToken) {
  return request(
    'save',
    'api-v1/accounts/{account_id}/move-plan',
    {
      account_id: accountId
    },
    {
      new_plan_id: newPlanId,
      period: period,
      stripe_token: stripeToken
    }
  )
}

// --------------------------------------------------------------------------------------------------
// ---------------------------------      SHARES     ------------------------------------------------
// --------------------------------------------------------------------------------------------------

export function fetchSharesSettings(id) {
  return request('get', 'api/share-settings?asset_id={id}', { id: id })
}

export function updateShareSettings(settingId, data) {
  return request(
    'patch',
    'api/share-settings/{share_setting_id}',
    {
      share_setting_id: settingId
    },
    data
  )
}

export function createShareSetting(data) {
  return request('save', 'api/share-settings', {}, data)
}

export function deleteShareSetting(id) {
  return request('delete', 'api/share-settings/{id}', {
    id: id
  })
}

// --------------------------------------------------------------------------------------------------
// ---------------------------------  END SHARES     ------------------------------------------------
// --------------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------------
// ---------------------------------  PROJECTS       ------------------------------------------------
// --------------------------------------------------------------------------------------------------

export function getProjects() {
  return request('get', 'api-v1/projects')
}

export function createProject(title, teamId, template, includeAssets) {
  return request(
    'save',
    'api/projects',
    {},
    {
      title: title,
      team_id: teamId,
      template: template,
      include_assets: includeAssets
    }
  )
}

export function updateProjectTitle(project, title) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { title: title }
  )
}

export function updateProjectIcon(project, icon) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { cover_icon: icon }
  )
}

export function updateProjectColor(project, color) {
  return request(
    'patch',
    'api/projects/{project_id}',
    { project_id: project.id },
    { cover_color: color }
  )
}

export function updateProjectLock(project, isLocked) {
  return request(
    'patch',
    'api-v1/projects/{project_id}',
    { project_id: project.id },
    { is_locked: isLocked }
  )
}

export function getProject(projectId) {
  return request(
    'get',
    'api-v1/projects/{project_id}',
    {
      project_id: projectId
    },
    null
  )
}

export function updateProjectScreenshot(project, screenshot) {
  return Vue.http
    .post('api-v1/projects/' + project.id + '/screenshot', {
      base64file: screenshot
    })
    .then((response) => {
      return response.body
    })
}

export function uploadAssetEmpty(project, extra) {
  let payload = new window.FormData()
  payload.append('empty', true)
  if (extra !== undefined) {
    if (extra.parent_id !== undefined) {
      payload.append('parent_id', extra.parent_id)
    }
  }

  return request(
    'save',
    'api-v1/projects/{project_id}/assets',
    { project_id: project.id },
    payload
  )
}

export function uploadAsset(project, file, options, asset, extra) {
  let payload = new window.FormData()
  if (extra !== undefined) {
    if (extra.parent_id !== undefined) {
      payload.append('parent_id', extra.parent_id)
    }

    if (extra.relative_path !== undefined) {
      payload.append('relative_path', extra.relative_path)
    }
  }

  if (asset !== undefined) {
    payload.append('asset_id', asset.id)
  }

  payload.append('file', file)

  return request(
    'save',
    'api-v1/projects/{project_id}/assets',
    { project_id: project.id },
    payload,
    options
  )
}

export function updateVersionCoverImage(data) {
  return request(
    'save',
    'api/asset-versions/{version_id}/cover',
    { version_id: data.version_id },
    data
  )
}

export function saveAssetVersionNewData(asset, data) {
  let payload = {
    data: data
  }

  return request(
    'save',
    'api/v1/assets/{asset_id}/versions',
    { asset_id: asset.id },
    payload
  )
}

export function uploadAssetVersion(file, asset, extra) {
  let payload = new window.FormData()
  payload.append('file', file)
  return request(
    'save',
    'api/v1/assets/{asset_id}/versions',
    { asset_id: asset.id },
    payload
  )
}

export function newAssetFromDirectUpload(data) {
  return request('save', 'api/v1/assets', {}, data)
}

export function updateAssetResource(data) {
  return request(
    'save',
    'api/v1/assets/{asset_id}/resources',
    { asset_id: data.asset_id },
    data
  )
}

export function updateAssetResourceAssociation(data) {
  return request(
    'patch',
    'api/assets/{asset_id}/resources',
    { asset_id: data.asset_id },
    data
  )
}

export function newFolder(project, parent, name) {
  return request(
    'save',
    'api/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'folder'
    }
  )
}

export function newPreviz(project, parent, name) {
  return request(
    'save',
    'api/v1/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'previz'
    }
  )
}

export function newComposition(project, parent, name) {
  return request(
    'save',
    'api/assets-browser',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: 'composition'
    }
  )
}

export function saveSequence(assetId, data) {
  return request(
    'save',
    'api/assets/{id}/sequence',
    {
      id: assetId
    },
    {
      data: data
    }
  )
}

export function newSequenceItem(sceneId, name) {
  return request(
    'save',
    'api/assets-browser/{id}/sequences',
    {
      id: sceneId
    },
    {
      name: name
    }
  )
}

export function newAssetItem(project, parent, name, type) {
  return request(
    'save',
    'api/v1/assets',
    {},
    {
      project_id: project.id,
      parent_id: parent,
      name: name,
      type: type
    }
  )
}

export function moveAssets(assets, folderId) {
  let ids = []
  assets.forEach((asset) => {
    ids.push(asset.id)
  })

  return request(
    'patch',
    'api/assets-browser/{asset_id}',
    {
      asset_id: ids.join(',')
    },
    {
      folder_id: folderId
    }
  )
}

export function renameAsset(asset, name) {
  return request(
    'patch',
    'api/v1/assets-browser/{asset_id}',
    {
      asset_id: asset.id
    },
    {
      name: name
    }
  )
}

export function updateAsset(asset_id, payload) {
  return request(
    'patch',
    'api/assets/{asset_id}',
    {
      asset_id: asset_id
    },
    payload
  )
}

export function updateAssetSettings(asset_id, settings) {
  return request(
    'save',
    'api/assets/{asset_id}/settings',
    {
      asset_id: asset_id
    },
    {
      settings: settings
    }
  )
}

export function deleteAssets(assets) {
  let ids = []
  assets.forEach((asset) => {
    ids.push(asset.id)
  })

  return request('delete', 'api/assets-browser/{asset_id}', {
    asset_id: ids.join(',')
  })
}

export function deleteProject(project) {
  return Vue.http.delete('api-v1/projects/' + project.id + '/trash')
}

export function restoreProject(project) {
  return Vue.http.post('api-v1/projects/' + project.id + '/restore')
}

export function archiveProject(projectId) {
  return request('save', 'api/projects/{project_id}/archive', {
    project_id: projectId
  })
}

export function unarchiveProject(projectId) {
  return request('save', 'api/projects/{project_id}/restore', {
    project_id: projectId
  })
}

export function reorderTeams(order) {
  return request(
    'save',
    'api/teams/reorder',
    {},
    {
      order: order
    }
  )
}
