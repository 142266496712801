import store from '@/store/index'

const axios = require('axios')

export default class PrevizDirectUpload {
  /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
  async store (file, options = {}) {
    const response = await axios.post('/api/v1/uploads/signed-storage-url', {
      'bucket': options.bucket || '',
      'content_type': options.contentType || file.type,
      'expires': options.expires || '',
      'visibility': options.visibility || ''
    }, {
      baseURL: process.env.VUE_APP_SERVER_API_BASE,
      headers: {
        'Authorization': store.getters['auth/token_type'] + ' ' + store.getters['auth/access_token'],
        'Accept': 'application/json'
      },
      ...options.options
    })

    // let headers = response.data.headers

    // if ('Host' in headers) {
    //   delete headers.Host
    // }

    if (typeof options.progress === 'undefined') {
      options.progress = () => { }
    }

    const cancelToken = options.cancelToken || ''

    await axios.put(
      response.data.data.url,
      file,
      {
        cancelToken: cancelToken,
        onUploadProgress: (progressEvent) => {
          options.progress(progressEvent.loaded / progressEvent.total)
        }
      })

    if (file.name !== undefined) response.data.data.extension = file.name.split('.').pop()
    else {
      if (file.type === 'image/png') response.data.data.extension = 'png'
      else if (file.type === 'image/jpg') response.data.data.extension = 'jpg'
    }

    if (file.type === '') {
      if (response.data.data.extension === 'mov') response.data.data.type = 'video/mov'
    }

    return response.data.data
  }
}

// export default PrevizDirectUpload()
// module.exports = new PrevizDirectUpload()
