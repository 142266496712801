import { noopPromise } from '@utils/misc.js'
import * as api from '@utils/api.js'

const state = {

  persistentKeys: ['w', 's', 't', 'a'], // workspace, scene, track, asset
  stateHash: {},

  navVisible: true,
  activeSidebar: false,
  activeTimeline: false,
  activeViewer: true,
  activeWorkspace: 'Viewer', // default to the viewer if nothing otherwise set

  projectOnboardingVisible: null,
  generalOnboardingVisible: null
}

const getters = {
  projectOnboardingVisible (state, getters) {
    if (state.projectOnboardingVisible === null) return getters.doesUserNeedOnboardingProject
    return state.projectOnboardingVisible
  },
  generalOnboardingVisible (state, getters) {
    if (state.generalOnboardingVisible === null) return getters.doesUserNeedOnboardingGeneral
    return state.generalOnboardingVisible
  }
}

const actions = {

  toggleGeneralOnboarding ({ commit, getters }) {
    if (getters.generalOnboardingVisible) commit('hideGeneralOnboarding')
    else commit('showGeneralOnboarding')
  },

  toggleProjectOnboarding ({ commit, getters }) {
    if (getters.projectOnboardingVisible) {
      commit('hideProjectOnboarding')

      if (getters.doesUserNeedOnboardingProject === true) {
        // hit the api to set the persistence on this
        return api.updateProfileOnboardingProjectComplete()
      }
    } else commit('showProjectOnboarding')
  },

  setDefaultWorkspaceForArea ({ commit, state }, { defaultWorkspace }) {
    commit('setDefaultWorkspace', { defaultWorkspace })
    return noopPromise()
  },

  setStateFromHash ({ commit, dispatch, state }) {
    commit('setStateFromHash')

    if ('w' in state.stateHash) {
      dispatch('setActiveWorkspace', { workspace: state.stateHash.w })
    }

    if ('s' in state.stateHash) {
      // Set active scene
      dispatch('setActiveSceneById', { sceneId: state.stateHash.s })
        .then(scene => {
          // Set the active track, only if the scene is also set.
          if ('t' in state.stateHash) {
            // Set active track
            dispatch('setActiveTrackById', { trackId: state.stateHash.t })
          }
        })
    }

    if ('a' in state.stateHash) {
      // Set active asset
      if (!isNaN(state.stateHash.a) && state.stateHash.a !== '') {
        if (state.activeWorkspace === 'Content') {
          dispatch('setActiveAssetById', { assetId: state.stateHash.a })
            .then(asset => {
              return dispatch('showAssetDetails', { asset })
            })
        }
      }
    }
  },

  setHashFromState ({ commit, state }) {
    commit('setHashFromState')
  },

  setNavHidden ({ commit }) {
    commit('setHidden')
  },

  setNavVisible ({ commit }) {
    commit('setVisible')
  },

  setActiveSidebarClosed ({ commit }) {
    commit('setActiveSidebarClosed')
  },

  setActiveSidebarOpen ({ commit }) {
    commit('setActiveSidebarOpen')
  },

  setActiveSidebar ({ commit }, { panel }) {
    commit('setActiveSidebar', { panel })
  },

  toggleTimeline ({ commit }) {
    commit('toggleTimeline')
  },

  disableViewer ({ commit }) {
    commit('disableViewer')
  },

  enableViewer ({ commit }) {
    commit('enableViewer')
  },

  enableViewerIfVisible ({ commit, state }) {
    if (state.activeWorkspace === 'Viewer') {
      commit('enableViewer')
    } else {
      commit('disableViewer')
    }
  },

  toggleViewer ({ commit }) {
    commit('toggleViewer')
  },

  setActiveAssetSidebar ({ commit, state }, { asset }) {
    if (asset === null || asset === undefined) {
      if (state.activeWorkspace === 'Content') {
        commit('setActiveSidebarClosed')
      } else {
        commit('setActiveSidebar', { panel: 'assets' })
      }
      commit('setHashKey', { key: 'a', value: '' })
    } else {
      commit('setActiveSidebar', { panel: 'asset' })
      commit('setHashKey', { key: 'a', value: asset.id })
    }
    commit('setHashFromState')
  },

  setActiveMobileWorkspace ({ commit, dispatch }, { workspace }) {
    // On mobile the Viewer is only ever visible in the Viewer workspace
    if (workspace !== 'Viewer') {
      commit('disableViewer')
    } else {
      commit('enableViewer')
    }

    return dispatch('setActiveWorkspace', { workspace })
  },

  setHashScene ({ commit, state }, { id }) {
    commit('setHashKey', { key: 's', value: id })
    commit('setHashFromState')
  },

  setHashTrack ({ commit, state }, { id }) {
    commit('setHashKey', { key: 't', value: id })
    commit('setHashFromState')
  }

}

const mutations = {

  hideGeneralOnboarding (state) {
    state.generalOnboardingVisible = false
  },

  hideProjectOnboarding (state) {
    state.projectOnboardingVisible = false
  },

  showProjectOnboarding (state) {
    state.projectOnboardingVisible = true
  },

  setDefaultWorkspace (state, { defaultWorkspace }) {
    state.activeWorkspace = defaultWorkspace
  },

  setHashKey (state, { key, value }) {
    if (key !== '' && value !== null) {
      state.stateHash[key] = value
    }
  },

  setHashFromState (state) {
    let out = []

    for (var key in state.persistentKeys) {
      if (state.persistentKeys[key] in state.stateHash) {
        if (state.stateHash[state.persistentKeys[key]] !== null) {
          out.push(state.persistentKeys[key] + '=' + encodeURIComponent(state.stateHash[state.persistentKeys[key]]))
        }
      }
    }

    window.location.hash = out.join('&')
  },

  setStateFromHash (state) {
    let hash = window.location.hash.slice(1)
    let res = hash.split('&')
    state.stateHash = {}
    res.forEach(row => {
      let parts = row.split('=')
      state.stateHash[parts[0]] = parts[1]
    })
  },

  setActiveWorkspace (state, { workspace }) {
    if (workspace === 'Viewer') {
      state.activeTimeline = false
      state.activeSidebar = false
      state.activeWorkspace = workspace
    }

    if (workspace === 'Sequencer') {
      state.activeTimeline = true
      state.activeSidebar = 'timeline-editor'
      state.activeWorkspace = workspace
    }

    if (workspace === 'SequencerReadonly') {
      state.activeTimeline = true
      state.activeSidebar = false
      state.activeWorkspace = 'Sequencer'
    }

    if (workspace === 'Content') {
      state.activeSidebar = false
      state.activeTimeline = false
      state.activeWorkspace = workspace
    }

    if (workspace === 'Configure') {
      state.activeTimeline = false
      state.activeSidebar = 'scene'
      state.activeWorkspace = workspace
    }

    if (workspace === null) {
      state.activeTimeline = false
      state.activeSidebar = false
      state.activeWorkspace = null
    }
  },

  setActiveSidebar (state, { panel }) {
    if (panel === state.activeSidebar) state.activeSidebar = false
    else state.activeSidebar = panel
  },

  toggleTimeline (state) {
    state.activeSidebar = 'content'
    state.activeTimeline = !state.activeTimeline
  },

  toggleViewer (state) {
    state.activeViewer = !state.activeViewer
  },

  enableViewer (state) {
    state.activeViewer = true
  },

  disableViewer (state) {
    state.activeViewer = false
  },

  setHidden (state) {
    state.navVisible = false
  },

  setVisible (state) {
    state.navVisible = true
  },

  setToggle (state) {
    state.navVisible = !state.navVisible
  },

  setActiveSidebarClosed (state) {
    state.activeSidebar = false
  },

  setActiveSidebarOpen (state) {
    state.activeSidebar = true
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
