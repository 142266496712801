import store from '../store'

export default [
  {
    path: '/editor/:uuid',
    name: 'editor',
    meta: {
      canSetAsFirst: true
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/Editor.vue'),
    beforeEnter: (to, from, next) => {
      const needsOnboarding = store.getters['doesUserNeedOnboardingPreviz']
      if (needsOnboarding) {
        next({ name: 'user-onboarding', query: { next: to.fullPath } })
        return
      }

      store
        .dispatch('assets/loadAsset', { id: to.params.uuid, force: true })
        .then((response) => {
          if (response.locked) {
            next({
              name: 'drive-overview',
              params: { uuid: response.drive_id }
            })
          }
          const driveId = response?.drive_id

          if (driveId) {
            store.dispatch('project/loadProject', {
              projectId: driveId
            })
          }
        })

      // let project = store.getters['project/project']
      // store.dispatch('assets/loadRootFolder', { project: project })
      store.dispatch('assets/loadChildSequences', { id: to.params.uuid })
      next()
    }
  }
]
