import store from '../store'

export default [
  {
    path: '/modal-testing',
    component: () => import(/* webpackChunkName: "testing" */ '@/views/testing/RawModal.vue')
  },
  {
    path: '/viewer-testing',
    component: () => import(/* webpackChunkName: "testing" */ '@/views/testing/TestSequence.vue')
  },
  {
    path: '/viewer-testing/:uuid',
    component: () => import(/* webpackChunkName: "testing" */ '@/views/testing/LiveSequence.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('assets/loadAsset', { id: to.params.uuid, force: true })
      next()
    }
  }

]
