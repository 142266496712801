import * as api from '@utils/api.js'

const state = {
  invitation: null,
  invitationIsValid: true,
  invitationErrorMessage: null
}

const actions = {
  fetchInvitation ({ commit }, { invitationId }) {
    if (typeof (invitationId) === 'undefined') {
      return null
    }

    return api.fetchInvitation(invitationId)
      .then(invitation => {
        commit('setValidInvitation', { invitation })
        return invitation
      })
      .catch(response => {
        let message = response.message
        commit('setInvalidInvitation', { message })
      })
  }
}

const mutations = {
  setValidInvitation (state, { invitation }) {
    state.invitation = invitation
    state.invitationIsValid = true
    state.invitationErrorMessage = null
  },

  setInvalidInvitation (state, { message }) {
    state.invitation = null
    state.invitationIsValid = false
    state.invitationErrorMessage = message
  }
}

export default {
  state,
  actions,
  mutations
}
