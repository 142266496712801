import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  accounts: [],
  invoices: [],
  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {
  getAccountByTeamId: (state) => (id) => {
    let found = state.accounts.find(account => account.team_id === id)

    if (found === undefined) return null
    return found
  },

  getAccountById: (state) => (id) => {
    let found = state.accounts.find(account => account.id === id)

    if (found === undefined) return null
    return found
  },

  // invoices (state) {
  //   let invoiceGroup = findInListByKey(state.invoices, 'account_id', state.activeAccount)
  //   if (invoiceGroup == null) return null

  //   return invoiceGroup.invoices
  // },

  loading (state) {
    return state.loading
  },

  loadingError (state) {
    return state.loadingError
  },

  loaded (state) {
    return state.loaded
  }
}

const actions = {

  setloading ({ commit, state }) {
    commit('setloading', state)
  },

  setloadingError ({ commit, state }) {
    commit('setloadingError', state)
  },

  setloaded ({ commit, state }) {
    commit('setloaded', state)
  },

  getAccountInvoices ({ state, commit }, { accountId }) {
    return api.getAccountInvoices(accountId)
      .then(invoices => {
        commit('addInvoices', { accountId, invoices })
        return invoices
      })
      .catch(error => {
        return error
      })
  },

  cancelAccount ({}, { accountId, comments }) { // eslint-disable-line no-empty-pattern
    return api.cancelAccount(accountId, comments)
  },

  reactivateAccount ({}, { accountId }) { // eslint-disable-line no-empty-pattern
    return api.reactivateAccount(accountId)
  },

  startSubscriptionEarly ({}, { accountId }) { // eslint-disable-line no-empty-pattern
    return api.startSubscriptionEarly(accountId)
  },

  getAccount ({ dispatch, state, commit }, { accountId }) {
    dispatch('setloading')
    return api.getAccount(accountId)
      .then(response => {
        commit('addAccount', response.data)
        dispatch('setloaded')
        return response.data
      })
      .catch(error => {
        dispatch('setloadingError')
        return error
      })
  },

  clearAccount ({ dispatch, commit }) {
    commit('clearAccount')
  },

  // eslint-disable-next-line no-empty-pattern
  fetchBillingPortalSession ({}, { accountId }) {
    return api.fetchBillingPortalSession(accountId)
      .then(result => {
        return result
      }).catch(error => {
        return error
      })
  },

  // eslint-disable-next-line no-empty-pattern
  fetchIntentToUpdatePaymentMethod ({}, { accountId, plan, period }) {
    let data = {
      accountId: accountId,
      plan: plan,
      period: period
    }

    return api.fetchIntentToUpdatePaymentMethod(data)
      .then(result => {
        return result
      }).catch(error => {
        return error
      })
  },

  updatePaymentMethod ({ commit }, { accountId, stripeToken }) {
    return api.updatePaymentMethod(accountId, stripeToken)
      .then(result => {
        return true
      }).catch(error => {
        return error
      })
  },

  updateAccountPlan ({ commit }, { accountId, newPlanId, period, stripeToken }) {
    return api.updateAccountPlan(accountId, newPlanId, period, stripeToken)
      .then(() => {
        return true
      }).catch(error => {
        return error
      })
  }
}

const mutations = {

  setloaded (state) {
    state.loaded = true
    state.loading = false
    state.loadingError = false
  },
  setloading (state) {
    state.loaded = false
    state.loading = true
    state.loadingError = false
  },
  setloadingError (state) {
    state.loaded = false
    state.loading = false
    state.loadingError = true
  },

  addInvoices (state, { accountId, invoices }) {
    // let arr = {
    //     account_id: accountId,
    //     invoices: invoices
    // };

    // Bypass a cache problem for now.
    // See also team.js > addTeamMembers & addTeamGuests
    let arr = { account_id: accountId, invoices: invoices }

    state.invoices = []
    state.invoices.push(arr)

    return invoices
  },

  addAccount (state, data) {
    state.accounts = updateInListByKey(state.accounts, 'id', data.id, data)
  },
  clearAccount (state) {
    state.accounts = []
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
