import { getInstance } from '../../auth/authWrapper.js'

const state = {
  token_type: 'Bearer',
  error: null,
  access_token: null
}

const getters = {
  error: (state) => {
    return state.error
  },

  token_type: (state) => {
    return state.token_type
  },

  access_token: (state) => {
    return state.access_token
  },

  isAuthed: (state) => {
    const authService = getInstance()
    return authService.isAuthenticated
  },

  isAuthedAndReady: (state) => {
    return state.access_token !== null
  }
}

const actions = {
  setAuthError ({ commit }, err) {
    commit('setAuthError', err)
  },

  clear ({ commit }) {
    window.localStorage.clear()

    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  },

  getAuth0Token ({ commit }) {
    return new Promise((resolve) => {
      const authService = getInstance()
      if (!authService.isAuthenticated) {
        resolve()
      }

      authService.getTokenSilently().then(token => {
        commit('storeAccessToken', token)
        resolve(token)
      })
    })
  }
}

const mutations = {
  setAuthError (state, err) {
    state.error = err
  },
  storeAccessToken (state, token) {
    state.access_token = token
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
