// import * as api from '@utils/api.js'

const state = {
  // UI Preferences
  appDarkMode: 'dark',

  // Assets
  assetsLayout: null,
  assetsSortOrder: null,
  assetsTreePanelOpen: null,

  // Persistent UI Features
  mobileOverlayHidden: true,
  navSidebarHidden: false,

  // navSidebarTeamsCollapsed: false,
  // navSidebarLearningCollapsed: false,
  // navSidebarSharedCollapsed: false,
  // collapsedTeams: [],

  // Project UI
  projectNavbarHidden: false,
  projectNavbarSupressingSidebar: false,

  // Viewer page
  viewerWideMode: true,

  // Editor
  editorLayoutSizes: null,

  // Billing Choices
  // Used during signup flow
  billingChoicePeriod: null,
  billingChoicePlan: null
}

const getters = {
  appDarkMode: (state) => {
    return 'dark'
    // return state.appDarkMode - Force dark mode regardless of user choice
  },

  billingChoicePeriod: (state) => {
    return state.billingChoicePeriod
  },

  billingChoicePlan: (state) => {
    return state.billingChoicePlan
  },

  hasBillingChoice: (state) => {
    return (
      state.billingChoicePlan !== null && state.billingChoicePlan !== undefined
    )
  },

  editorLayoutSizes(state) {
    return state.editorLayoutSizes
  },

  assetsLayout(state) {
    return state.assetsLayout
  },

  assetsSortOrder(state) {
    return state.assetsSortOrder
  },

  assetsTreePanelOpen(state) {
    return state.assetsTreePanelOpen
  },

  viewerWideMode(state) {
    return state.viewerWideMode
  },

  navSidebarHidden(state) {
    return state.navSidebarHidden
  },

  mobileOverlayHidden(state) {
    return state.mobileOverlayHidden
  },

  projectNavbarHidden(state) {
    return state.projectNavbarHidden
  }
}

const mutations = {
  STORE_DARK_MODE_CHOICE(state, choice) {
    state.appDarkMode = 'dark' // Force dark mode regardless of user choice
  },

  STORE_PLAN_CHOICE(state, { plan, period }) {
    state.billingChoicePlan = plan
    state.billingChoicePeriod = period
  },

  setEditorLayoutSizes(state, sizes) {
    state.editorLayoutSizes = sizes
  },

  setAssetsLayout(state, layout) {
    state.assetsLayout = layout
  },

  setAssetsSortOrder(state, order) {
    state.assetsSortOrder = order
  },

  setAssetsTreePanelOpen(state, open) {
    state.assetsTreePanelOpen = open
  },

  setNavSidebarHidden(state, value) {
    state.navSidebarHidden = Boolean(value)
  },

  toggleSidebarHidden(state) {
    state.navSidebarHidden = !state.navSidebarHidden
  },

  setViewerWideMode(state, value) {
    state.viewerWideMode = Boolean(value)
  },

  setMobileOverlayHidden(state, value) {
    state.mobileOverlayHidden = Boolean(value)
  },

  toggleMobileOverlayHidden(state) {
    state.mobileOverlayHidden = !state.mobileOverlayHidden
  },

  setProjectNavbarHidden(state, value) {
    value = Boolean(value)
    state.projectNavbarHidden = value

    if (value === true) {
      if (
        state.navSidebarHidden === false &&
        state.navSidebarHidden === false
      ) {
        // Force the sidebar hidden for now too
        state.projectNavbarSupressingSidebar = true
        state.navSidebarHidden = true
      }
    } else {
      if (state.projectNavbarSupressingSidebar === true) {
        state.projectNavbarSupressingSidebar = false
        state.navSidebarHidden = false
      }
    }
  }
}

const actions = {
  setAppDarkMode({ commit }, { choice }) {
    commit('STORE_DARK_MODE_CHOICE', choice)
  },

  updateEditorLayoutSizes({ commit }, { sizes }) {
    commit('setEditorLayoutSizes', sizes)
  },

  updateAssetsLayout({ commit }, { layout }) {
    commit('setAssetsLayout', layout)
  },

  updateAssetsSortOrder({ commit }, { order }) {
    commit('setAssetsSortOrder', order)
  },

  updateAssetsTreePanelOpen({ commit }, { open }) {
    commit('setAssetsTreePanelOpen', open)
  },

  toggleSidebarHidden({ commit }) {
    commit('toggleSidebarHidden')
  },

  setNavSidebarHidden({ commit }, value) {
    commit('setNavSidebarHidden', value)
  },

  setMobileOverlayHidden({ commit }, value) {
    commit('setMobileOverlayHidden', value)
  },

  toggleMobileOverlayHidden({ commit }) {
    commit('toggleMobileOverlayHidden')
  },

  setViewerWideMode({ commit }, value) {
    commit('setViewerWideMode', value)
  },

  setProjectNavbarHidden({ commit }, value) {
    commit('setProjectNavbarHidden', value)
  },

  storePlanChoice({ commit }, { plan, period }) {
    commit('STORE_PLAN_CHOICE', { plan: plan, period: period })
  },

  clearPlanChoice({ commit }) {
    commit('STORE_PLAN_CHOICE', { plan: null, period: null })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
