/* eslint no-undef: 1 */
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'
import * as actions from './actions'

import active from './modules/active'
import projects from './modules/projects'
import settings from './modules/settings'
import user from './modules/user'
import team from './modules/team'
import teams from './modules/teams'
import members from './modules/members'
import notifications from './modules/notifications'
// import messages from './modules/messages'
import invitation from './modules/invitation'
import nav from './modules/nav'
import account from './modules/account'
import shares from './modules/shares'
import shared from './modules/shared'
import plans from './modules/plans'

// import activity from './modules/activity'
import projectsList from './modules/projectsList'
import archivedProjects from './modules/archivedProjects'
import project from './modules/project'
import app from './modules/app'
import auth from './modules/auth'
import preferences from './modules/preferences'
import assets from './modules/assets'
import search from './modules/search'
import sequencer from './modules/sequencer'
import assetVersions from './modules/assetVersions'
import jobs from './modules/jobs'
import comments from './modules/comments'
import librarycontent from './modules/librarycontent'

Vue.use(Vuex)

// initial state
let initialState = {
  user: user.state,
  shared: shared.state
}

const vuexCookie = new VuexPersistence({
  key: process.env.VUE_APP_PREVIZ_VUEX_LOCALKEY, // The key to store the state on in the storage provider.
  restoreState: (key, storage) => Cookies.getJSON(key),
  saveState: (key, state, storage) =>
    Cookies.set(key, state, {
      expires: 1
    }),
  modules: ['auth', 'preferences'] // only save auth and preferences module
})

const vuexLocalStorage = new VuexPersistence({
  key: process.env.VUE_APP_PREVIZ_VUEX_LOCALKEY, // The key to store the state on in the storage provider.
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    shared: shared.state
  })
})

let store = new Vuex.Store({
  actions,
  modules: {
    active,
    projects,
    settings,
    user,
    preferences,
    assets,
    search,
    sequencer,
    assetVersions,
    team,
    teams,
    members,
    notifications,
    invitation,
    nav,
    account,
    shares,
    shared,
    projectsList,
    archivedProjects,
    project,
    app,
    auth,
    plans,
    jobs,
    comments,
    librarycontent
  },
  mutations: {
    resetVuexAll(state) {
      Object.keys(state).forEach((key) => {
        if (initialState[key] !== undefined) {
          Object.assign(state[key], initialState[key])
        }
      })
    }
  },
  namespaced: true,
  strict: process.env.VUE_APP_MODE === 'local',
  plugins: [vuexCookie.plugin, vuexLocalStorage.plugin]
})

// Ensure exceptions thrown in Promise in Vuex actions are reported on the console
// This is probably not the right way to do it:
// 1. The vuex:error message is undocumented (see vuex store.js registerAction for it's definition
// 2. store._devtoolHook doesn't exist in production
// I've (@charlesfleche) posted a question on SO about the best way to solve this
// https://stackoverflow.com/questions/43149716/catch-all-exception-in-vue-action
if (store._devtoolHook) {
  store._devtoolHook.on('vuex:error', (err) => {
    console.error(err)
  })
}

export default store
