/* eslint no-undef: 2 */
import * as api from '@utils/api.js'

const state = {
  user: null,
  error: null,

  tokens: [],

  firstProject: null,
  firstPath: null,
  lastDrive: null,
  lastDrivePath: null
}

const getters = {
  firstProject(state) {
    return state.firstProject
  },

  firstPath(state) {
    return state.firstPath
  },

  lastDrive(state) {
    return state.lastDrive
  },

  lastDrivePath(state) {
    return state.lastDrivePath
  },

  user: (state, rootGetters) => {
    if (rootGetters['auth/isAuthed']) return state.user
    return null
  },

  error: (state) => {
    return state.error
  },

  isDeveloper: (state, getters) => {
    if (
      state.user &&
      process.env.VUE_APP_SPACES_DEVELOPERS &&
      process.env.VUE_APP_SPACES_DEVELOPERS !== ''
    ) {
      return process.env.VUE_APP_SPACES_DEVELOPERS.includes(
        '|' + state.user.email + '|'
      )
    }
    return false
  },

  doesUserNeedPasswordUpdate: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_password
  },

  doesUserNeedProfileUpdate: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_profile
  },

  doesUserNeedEmailValidation: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_email_validation
  },

  doesUserNeedOnboarding: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_onboarding
  },

  doesUserNeedOnboardingPreviz: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_onboarding_previz
  },

  doesUserNeedOnboardingProject: (state, getters) => {
    if (getters.user == null) return false
    return getters.user.needs_onboarding_project
  }
}

const actions = {
  setFirst({ commit }, { project, path }) {
    commit('SET_FIRST', { project: project, path: path })
  },

  setLastDrive({ commit }, { drive, path }) {
    commit('SET_LAST_DRIVE', { drive: drive, path: path })
  },

  clearError({ commit }) {
    commit('clearError')
  },

  // A temporary fix for the first run setup problem
  // Dev only. To remove very soon. (ie, this week)
  forceCreateUserDev({ commit }, payload) {
    let uri = 'api/dev/create-user'
    return api.rawApiPostCall(uri, payload)
  },

  setNeedsOnboarding({ commit, rootGetters }, payload) {
    /*
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        needs_onboarding: payload
      })
      .then((response) => {
        commit('setCurrentUser', { user: response.data })
      })
      .catch((error) => {
        console.error(error)
      })
      */
  },

  setNeedsOnboardingPreviz({ commit, rootGetters }, payload) {
    const user = rootGetters.user

    return api
      .updateUser(user.id, {
        needs_onboarding_previz: payload
      })
      .then((response) => {
        commit('setCurrentUser', { user: response.data })
      })
      .catch((error) => {
        console.error(error)

        return false
      })
  },

  fetchAuthCheck() {
    let uri = 'api/v1/auth-check'
    return api.rawApiGetCall(uri)
  },

  fetchUser({ commit }) {
    let uri = 'api/v1/me'

    return api
      .rawApiGetCall(uri)
      .then((response) => {
        let user = response.data.data
        commit('clearError')
        commit('setCurrentUser', { user })

        commit(
          'preferences/STORE_DARK_MODE_CHOICE',
          user.preference_lightmode,
          {
            root: true
          }
        )
        return user
      })
      .catch((err) => {
        commit('setError', { err })
        return false
      })
  }
}

const mutations = {
  SET_FIRST(state, { project, path }) {
    state.firstProject = project
    state.firstPath = path
  },

  SET_LAST_DRIVE(state, { drive, path }) {
    state.lastDrive = drive
    state.lastDrivePath = path
  },

  setError(state, { err }) {
    state.error = err
  },

  clearError(state) {
    state.error = null
  },

  setCurrentUser(state, { user }) {
    state.user = user
  },

  userSetPasswordUpdated(state) {
    state.user.needs_password = false
  },

  userSetProfileUpdated(state) {
    state.user.needs_profile = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
