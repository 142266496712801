const state = {
  version: '4.10.4',
  pwaInstallPrompt: null,

  // App Feature Gates
  appSearchEnabled: false,
  appNotificationsEnabled: false,
  appServicesEnabled: false,
  appBillingEnabled: true,

  editorMerged: true,

  itemEmbedsEnabled: false,

  itemCommentsEnabled: true,
  itemActivityEnabled: false,
  itemVersionsEnabled: false,
  itemConnectionsEnabled: false,

  contentCompositionsEnabled: true,

  projectPresenseEnabled: false,
  projectServicesEnabled: false,
  projectAssetContextMenuEnabled: false,

  editorCameraBookmarkInspectorEnabled: false
}

const getters = {
  version (state) {
    return state.version
  },

  getFlag: state => flag => {
    return state[flag]
  },

  pwaInstallAvailable (state) {
    return state.pwaInstallPrompt !== null
  },

  pwaInstallPrompt (state) {
    return state.pwaInstallPrompt
  }
}

const mutations = {
  SET_FLAG (state, { key, value }) {
    state[key] = value
  },

  setPWAInstallPrompt (state, prompt) {
    state.pwaInstallPrompt = prompt
  }
}

const actions = {
  setFlag ({ commit }, { key, value }) {
    commit('SET_FLAG', { key: key, value: value })
  },

  handlePWABeforeInstallPrompt ({ commit }, { prompt }) {
    commit('setPWAInstallPrompt', prompt)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
