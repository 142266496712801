/* global alert */
import Vue from 'vue'
import vuePreviz from '@utils/vue-previz'
import VTooltip from 'v-tooltip'
import FloatingVue from 'floating-vue'

import 'floating-vue/dist/style.css'

window.Promise = require('promise')
window.alert = require('alertify.js')
window.Spark = {}

// Set our default alertify settings
// Show bottom right, 5 seconds auto-dismissing, close on click, max 5 items
alert.logPosition('top right').delay(5000).closeLogOnClick(true).maxLogItems(5)

Vue.use(vuePreviz)

let TooltipPpts = {
  defaultDelay: 0,
  disposeTimeout: 1000,
  hideOnTargetClick: true,
  popover: {
    defaultDelay: 0
  }
}

Vue.use(VTooltip, TooltipPpts)

let FloatingVueConfig = {
  placement: 'top',
  delay: 0,
  container: 'body',
  boundary: undefined,
  autoHide: true,
  disposeTimeout: 1000,
  themes: {
    tooltip: {
      triggers: ['hover', 'focus'],
      hideTriggers: (triggers) => [...triggers, 'click'],
      loadingContent: '...',
      $resetCss: true
    },
    dropdown: {
      placement: 'bottom',
      delay: 0,
      triggers: ['click'],
      container: 'body',
      boundary: undefined,
      autoHide: true,
      handleResize: true,
      $resetCss: true
    }
  }
}
Vue.use(FloatingVue, FloatingVueConfig)

require('./forms/bootstrap')

// Load the Dropbox Chooser script.
require('@/vendor/dropbox-chooser')

// Error handling for Sentry
// window.Sentry = require('@sentry/browser')
// window.SentryIntegrations = require('@sentry/integrations')

// window.Sentry.init({
//   dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
//   environment: process.env.VUE_APP_MODE,
//   integrations: [new SentryIntegrations.Vue({
//     Vue,
//     attachProps: true
//   })]
// })
