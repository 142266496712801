import * as api from '@utils/api.js'

const state = {
  notifications: []
}

const actions = {
  getNotifications ({ commit }) {
    return api.getNotifications()
      .then(notifications => {
        commit('setNotifications', { notifications })
        return notifications
      })
  }
}

const mutations = {
  setNotifications (state, { notifications }) {
    state.notifications = notifications
  }
}

export default {
  state,
  actions,
  mutations
}
