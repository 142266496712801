import Echo from 'laravel-echo'
import store from './store'
window.Pusher = require('pusher-js')

const echoInit = () => {
  if (store.getters['auth/access_token'] !== null) {
    let auth = {
      headers:
      {
        'Authorization': store.getters['auth/token_type'] + ' ' + store.getters['auth/access_token'],
        'Accept': 'application/json'
      }
    }

    window.Echo = new Echo({
      broadcaster: 'pusher',
      authEndpoint: process.env.VUE_APP_SERVER_API_BASE + 'api-ws/broadcasting/auth',
      auth: auth,
      disableStats: true,
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: process.env.VUE_APP_PUSHER_ENCRYPTED,
      namespace: process.env.VUE_APP_PUSHER_NAMESPACE
    })
  } else {
    window.Echo = false
  }
}

export default echoInit
