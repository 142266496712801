export const appDarkMode = {

  computed: {
    appDarkMode () {
      return this.$store.getters['preferences/appDarkMode']
    }
  },

  mounted () {
    this.updateBaseClass()
    this.attachSystemChangeListener()
  },

  watch: {
    appDarkMode () {
      this.updateBaseClass()
    }
  },

  methods: {
    updateBaseClass () {
      if (this.appDarkMode === 'dark') this.addDarkClass()
      else if (this.appDarkMode === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) this.addDarkClass()
      else this.removeDarkClass()
    },

    attachSystemChangeListener () {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        this.updateBaseClass()
      })
    },

    addDarkClass () {
      document.querySelector('html').classList.add('dark')
    },

    removeDarkClass () {
      document.querySelector('html').classList.remove('dark')
    }
  }

}
