import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  items: [],
  seen: [],

  assets: [],

  loading: false,
  loadingError: false,
  loaded: false

}

const getters = {

  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  },

  getSharedBySlug: (state) => (slug) => {
    console.log('Get shared by slug - ', slug, state.items)
    let found = state.items.find(row => row.slug === slug)
    if (found === undefined) return null
    return found
  },

  getAssetById: (state) => (id) => {
    let found = state.assets.find(row => row.id === id)
    if (found === undefined) return null
    return found
  },

  shared (state) {
    return state.items
  }
}

const actions = {

  loadShared ({ commit }, { slug }) {
    let uri = 'api/shares?slug=' + slug

    commit('setLoading')
    return api.rawApiGetCall(uri)
      .then(response => {
        let data = response.data.data
        commit('addShared', data)
        commit('setLoaded')
        return data
      })
      .catch(() => {
        commit('setLoadingError')
      })
  }
}

const mutations = {
  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  addShared (state, item) {
    // If the asset is included here, trim it out into it's own array
    // for easier access
    let asset = null

    if (item.asset !== undefined) {
      asset = item.asset.data
      item.asset_id = asset.id // Just in case

      delete item.asset
    }

    state.items = updateInListByKey(state.items, 'id', item.id, item)
    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }

    if (asset !== null) {
      state.assets = updateInListByKey(state.assets, 'id', asset.id, asset)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
