/*
  Assets Versions module
*/
import * as api from '@utils/api.js'
import { findManyInListByKey, updateInListByKey } from '@utils/misc.js'

const state = {
  items: [],
  seen: [],

  hasMore: false,
  totalRecords: null,
  loaded: false,
  loading: false,
  loadingError: false,

  nextPageLink: null,
  prevPageLink: null
}

const getters = {

  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  },

  getVersionsForAsset: (state) => (id) => {
    let found = findManyInListByKey(state.items, 'asset_id', id)
    return found
  }
}

const mutations = {
  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  addVersions (state, versions) {
    versions.forEach(data => {
      let item = data.data
      state.items = updateInListByKey(state.items, 'id', item.id, item)
      if (!state.seen.includes(item.id)) {
        state.seen.push(item.id)
      }
      // Also log the parent asset id as seen
      if (!state.seen.includes(item.asset_id)) {
        state.seen.push(item.asset_id)
      }
    })
  }
}

const actions = {

  softLoad ({ state, dispatch }, { asset }) {
    if (state.seen.includes(asset.id)) return
    dispatch('loadVersionsForAsset', { asset })
  },

  loadVersionsForAsset ({ commit, dispatch }, { asset }) {
    // Base Api call
    let uri = 'api/v1/assets/' + asset.id + '/versions'
    commit('setLoading')
    return api.rawApiGetCall(uri)
      .then(response => {
        dispatch('handleReturnVersions', { data: response.data })
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  handleReturnVersions ({ commit, dispatch }, { data }) {
    commit('addVersions', data.data)
    commit('setLoaded')
    // let itemsLink = findInListByKey(data.links, 'rel', 'assets-browser.children')
    // if (itemsLink && shallow !== true) {
    //   commit('setNextPageLink', itemsLink.url)
    //   dispatch('loadNext', { group: group })
    // }

    // let ancestorsLink = findInListByKey(data.links, 'rel', 'assets-browser.ancestors')
    // if (ancestorsLink && shallow !== true) {
    //   dispatch('loadAncestorsIfNeeded', { group, parentId, ancestorsLink })
    // }
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
