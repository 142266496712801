import Vue from 'vue'
import PrevizApp from './App.vue'
import router from './router'
import store from './store/index'
import './registerServiceWorker'
import NProgress from 'vue-nprogress'
import coreMixin from '@mixins/previz'
import echoInit from './echo.js'
import { Auth0Plugin } from './auth'

import './main.css'

// require('./assets/sass/app.scss')
require('./components/bootstrap')
require('./bootstrap/index')

Vue.config.productionTip = false

// Init Echo
echoInit()

// Set the user context after the store has been initialized
// window.Sentry.setUser(store.getters.user)

// Create the global event bus.
const bus = new Vue({})
Object.defineProperty(Vue.prototype, '$bus', {
  get () {
    return this.$root.bus
  }
})

Vue.use(NProgress)
const nprogress = new NProgress({ latencyThreshold: 250, showSpinner: false, http: false })

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENTID,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

// Create the main Vue application.
new Vue({ // eslint-disable-line no-new
  el: '#previz-app-root',
  nprogress,
  router,
  store,
  mixins: [coreMixin],
  data: {
    bus: bus
  },
  mounted () {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // and stash the event so it can be triggered later.
      e.preventDefault()
      this.$store.dispatch('app/handlePWABeforeInstallPrompt', { prompt: e })
    })
  },
  render: function (createElement) {
    return createElement(PrevizApp)
  }
})
