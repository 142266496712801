const PUBLIC_BUCKET_PATH =
  'https://dc-public-cdn1.s3.eu-west-2.amazonaws.com/previzDefaultScenes'

const state = {
  demoscenes: [
    {
      id: 'demo-1',
      title: 'Designer Base Scene',
      description: 'An empty plane of (emotional) emptiness.',
      src: `${PUBLIC_BUCKET_PATH}/designerDefaultScene.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_DesignerDefault.png`,
      settings: {}
    },
    {
      id: 'demo-2',
      title: '3 Screen Stage',
      description: 'A theatre like 3 screen setting on a stage.',
      src: `${PUBLIC_BUCKET_PATH}/3ScreenScene.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_3ScreenScene.png`
    },
    {
      id: 'demo-3',
      title: 'Warehouse',
      description: 'A textured warehouse containing 5 screens.',
      src: `${PUBLIC_BUCKET_PATH}/warehouseScene.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_warehouse.png`
    },
    {
      id: 'demo-4',
      title: '"XR" Cube',
      description: 'An XR volume with 3 screens.',
      src: `${PUBLIC_BUCKET_PATH}/XRCube.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_XRCube.png`
    },
    {
      id: 'demo-5',
      title: '270 Volume',
      description: 'A 270 curved screen sitting on a led floor.',
      src: `${PUBLIC_BUCKET_PATH}/270CurvedScene.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_270CurvedVolume.png`
    },

    {
      id: 'demo-6',
      title: 'Car Scene',
      description: 'A car model with a screen behind.',
      src: `${PUBLIC_BUCKET_PATH}/carScene.json`,
      cover: `${PUBLIC_BUCKET_PATH}/thumbnail_CarScene.png`
    }
  ]
}

const getters = {
  demoscenes(state) {
    return state.demoscenes
  }
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
