import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],
  loadedAll: false,
  loaded: false,
  loading: false,
  loadingError: false,

  info: {
    free: {
      title: 'Starter',
      badge_color: 'bg-flatgray-100 text-flatgray-800',
      description: 'Great for getting started, hosting personal projects, or experiments.',
      upgrades_to: 'pro',
      downgrades_to: false,
      features: ['Maximum 3 Members', '3 Projects', '2GB Storage', 'Community Support']
    },
    pro: {
      title: 'Pro',
      badge_color: 'bg-blue-100 text-blue-600',
      description: 'For creative professionals looking to showcase their work with clients.',
      upgrades_to: 'studio',
      downgrades_to: 'free',
      features: ['20 Projects', '100GB Storage', 'Password Protected Links', 'Archive Projects']
    },
    studio: {
      title: 'Studio',
      badge_color: 'bg-teal-100 text-teal-600',
      description: 'For comprehensive collaboration, team management and increased storage.',
      upgrades_to: false,
      downgrades_to: 'pro',
      features: ['Pro features, plus', '50 Projects', '1TB Storage', 'Version Management', 'Multiple Account Owners', 'Billing Administrator Role']
    },
    enterprise: {
      title: 'Enterprise',
      badge_color: 'bg-indigo-100 text-indigo-600',
      description: 'Packages custom designed for your requirements.',
      upgrades_to: false,
      downgrades_to: false,
      features: ['Unlimited Members', 'Unlimited Projects', '10TB Storage', 'Version Management', 'Model Optimization', 'Offline Billing']
    },
    custom: {
      title: 'Custom',
      badge: 'bg-yellow-100 text-yellow-800',
      description: 'This is a placeholder for a custom plan.',
      upgrades_to: false,
      downgrades_to: false,
      features: ['Custom features.']
    },

    founder: {
      title: 'Founder',
      badge: 'bg-yellow-100 text-yellow-800',
      description: 'Internal top level plan. Everything unlocked and accessible.',
      upgrades_to: false,
      downgrades_to: false,
      features: ['Unlimited Members', 'Unlimited Projects', 'Unlimited Storage', 'Pre Release Features']
    }
  }
}

const getters = {

  getInfoByType: (state) => (type) => {
    if (state.info[type] !== undefined) {
      return state.info[type]
    }

    return null
  },

  getPlans (state) {
    return state.data
  },

  getPlanByType: (state) => (type) => {
    let found = state.data.find(plan => (plan.type === type))

    if (found === undefined) return null
    return found
  },

  getPlanById: (state) => (id) => {
    let found = state.data.find(plan => (plan.id === id || plan.slug === id))

    if (found === undefined) return null
    return found
  },

  loading (state) {
    return state.loading
  },

  loadingError (state) {
    return state.loadingError
  },

  loaded (state) {
    return state.loaded
  },

  loadedAll (state) {
    return state.loadedAll
  }
}

const actions = {

  setloading ({ commit, state }) {
    commit('setloading', state)
  },

  setloadingError ({ commit, state }) {
    commit('setloadingError', state)
  },

  setloaded ({ commit, state }) {
    commit('setloaded', state)
  },

  fetchPlan ({ commit, getters }, { slug }) {
    let plan = getters.getPlanById(slug)
    if (plan !== null) return

    return api.fetchBillingPlan(slug)
      .then(response => {
        commit('addPlan', response.data)
        commit('setloaded')
        return true
      })
  },

  fetchPlansHard ({ commit, dispatch }) {
    commit('clear')
    dispatch('fetchPlans')
  },

  fetchPlans ({ getters, commit }) {
    if (getters.loadedAll === true) return

    return api.fetchBillingPlans()
      .then(response => {
        response.data.forEach(plan => {
          commit('addPlan', plan.data)
        })

        commit('setLoadedAll')
        commit('setloaded')
        return true
      })
      .catch(error => {
        console.error(error)
        return null
      })
  }
}

const mutations = {

  setLoadedAll (state) {
    state.loadedAll = true
  },
  setloaded (state) {
    state.loaded = true
    state.loading = false
    state.loadingError = false
  },
  setloading (state) {
    state.loaded = false
    state.loading = true
    state.loadingError = false
  },
  setloadingError (state) {
    state.loaded = false
    state.loading = false
    state.loadingError = true
  },

  addInvoices (state, { accountId, invoices }) {
    // let arr = {
    //     account_id: accountId,
    //     invoices: invoices
    // };

    // Bypass a cache problem for now.
    // See also team.js > addTeamMembers & addTeamGuests
    let arr = { account_id: accountId, invoices: invoices }

    state.invoices = []
    state.invoices.push(arr)

    return invoices
  },

  addPlan (state, data) {
    state.data = updateInListByKey(state.data, 'id', data.id, data)
  },

  clear (state) {
    state.data = []
    state.loading = false
    state.loaded = false
    state.loadingError = false
    state.loadedAll = false
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
