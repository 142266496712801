/*
  Archived Projects module
*/
import * as api from '@utils/api.js'
import filter from 'lodash/filter'

const state = {
  data: [],
  seen: [],
  selectedPos: null,

  loadedOn: null,
  hasMore: false,
  totalRecords: null,
  loaded: false,
  loading: false,
  loadingError: false,

  nextPageLink: null,
  prevPageLink: null
}

const getters = {
  projects (state) {
    return state.data
  },
  hasMore (state) {
    return state.hasMore
  },
  totalRecords (state) {
    return state.totalRecords
  },
  nextPageLink (state) {
    return state.nextPageLink
  },
  prevPageLink (state) {
    return state.prevPageLink
  },
  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  },
  loadedAll (state) {
    return state.loaded && (state.hasMore === false)
  },
  projectsForTeam: (state) => (teamId) => {
    let projects = filter(state.data, function (project) {
      return String(project.team_id) === String(teamId)
    })

    return projects
  }
}

const mutations = {
  removeProject (state, projectId) {
    // Also remove from seen
    var i = state.data.findIndex(row => row.id === projectId)
    if (i > -1) state.data.splice(i, 1)
  },

  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
    state.loadedOn = new Date()
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  addData (state, data) {
    let length = data.length

    for (let index = 0; index < length; index++) {
      let value = data[index].id

      if (!state.seen.includes(value)) {
        state.seen.push(value)
        state.data.push(data[index])
      }
    }
  },
  setHasMore (state, more) {
    state.hasMore = more
  },
  setTotalRecords (state, total) {
    state.totalRecords = total
  },
  setNextPageLink (state, url) {
    state.nextPageLink = url
  },
  setPrevPageLink (state, url) {
    state.prevPageLink = url
  },

  clear (state) {
    state.data = []
    state.seen = []
    state.hasMore = false
    state.loaded = false
    state.loading = false
    state.loadingError = false
  }

}

const actions = {
  removeProject ({ commit }, { projectId }) {
    commit('removeProject', projectId)
  },

  clear ({ commit }) {
    commit('clear')
  },

  loadProjects ({ commit, dispatch }, { teamId }) {
    console.log('load archived projects..')
    // Base Api call
    commit('clear')

    let uri = 'api/archived-projects?team_id=' + teamId
    commit('setLoading')
    return api.rawApiGetCall(uri)
      .then(response => {
        dispatch('handleReturn', response.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadNext ({ dispatch, state }) {
    let uri = state.nextPageLink
    return api.rawApiGetCall(uri)
      .then(response => {
        dispatch('handleReturn', response.data)
      })
  },

  setLoading ({ commit, state }) {
    commit('setLoading', state)
  },

  handleReturn ({ commit, dispatch }, data) {
    let dataCleaned = []
    data.data.forEach(row => {
      dataCleaned.push(row.data)
    })

    let hasMore = false
    data.pagination.links.forEach(row => {
      if (row.rel === 'pagination.next') {
        commit('setNextPageLink', row.url)
        hasMore = true
      }
      if (row.rel === 'pagination.previous') {
        commit('setPrevPageLink', row.url)
      }
    })

    let totalRecords = data.pagination.total_count

    commit('setHasMore', hasMore)
    commit('addData', dataCleaned)
    commit('setTotalRecords', totalRecords)

    if (hasMore) {
      dispatch('loadNext')
    } else {
      commit('setLoaded')
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
