import store from '../store'
import { getInstance } from '../auth/authWrapper'

export default [
  // Login / Auth Flow
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@views/Login.vue'),
    meta: {
      pageClass: 'login-page',
      isAuthenticated: false
    },
    beforeEnter: (to, from, next) => {
      // Old login flow, keeping the route for legacy reasons,
      // but this will redirect to the new login flow with Auth0
      next('/')
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "auth" */ '@views/Logout.vue'),
    meta: {
      pageClass: 'login-page',
      isAuthenticated: false
    },
    beforeEnter: (to, from, next) => {
      const authService = getInstance()
      store.dispatch('auth/clear')
      authService.logout({
        returnTo: `${process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL}/logout`
      })
      window.location = `${process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL}/logout` // backup incase authService hasn't loaded

      next()
    }
  },

  {
    path: '/clear-auth',
    name: 'clear-auth',
    component: () => import(/* webpackChunkName: "auth" */ '@views/Logout.vue'),
    meta: {
      pageClass: 'login-page',
      isAuthenticated: false
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/clear')
      next()
    }
  }
]
