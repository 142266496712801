const state = {
  jobs: []
}

const getters = {
  active (state) {
    return state.jobs
  }
}

const mutations = {
}

const actions = {

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
