/* eslint-disable no-empty-pattern */
/* eslint-disable camelcase */

// This files defines higher level actions that hits several stores
import * as api from '@utils/api.js'

export const getTeam = ({ commit }, { teamId }) => {
  return api.getTeam(teamId)
}

export const login = ({}, { email, password }) => {
  return api.login(email, password)
}

export const promoteTeamGuest = ({}, { team, member }) => {
  return api.promoteTeamGuest(team, member)
}

export const removeTeamGuest = ({}, { team, member }) => {
  return api.removeTeamGuest(team, member)
}

export const promoteTeamMember = ({}, { team, member }) => {
  return api.promoteTeamMember(team, member)
}

export const downgradeTeamMember = ({}, { team, member }) => {
  return api.downgradeTeamMember(team, member)
}

export const disableTeamMember = ({}, { team, member }) => {
  return api.disableTeamMember(team, member)
}
export const enableTeamMember = ({}, { team, member }) => {
  return api.enableTeamMember(team, member)
}
export const removeTeamMember = ({}, { team, member }) => {
  return api.removeTeamMember(team, member)
}
export const closeTeam = ({}, { team }) => {
  return api.closeTeam(team.id)
}
export const updateTeamMemberAccess = ({}, { team, memberId, accessAll, accessProjects }) => {
  return api.updateTeamMemberAccess(team, memberId, accessAll, accessProjects)
}
export const updateTeamGuestAccess = ({}, { team, memberId, accessProjects }) => {
  return api.updateTeamGuestAccess(team, memberId, accessProjects)
}

export const inviteTeamMember = ({}, { email, team, projectIds }) => {
  return api.inviteTeamMember(email, team, projectIds)
}

export const inviteTeamAdmin = ({}, { email, team }) => {
  return api.inviteTeamAdmin(email, team)
}

export const inviteTeamByEmail = ({}, { email, team, role, message }) => {
  return api.inviteTeamByEmail(email, team, role, message)
}

export const updatePhoto = ({}, data) => {
  return api.updatePhoto(data)
}

export const updatePassword = ({}, { currentPassword, newPassword, newPasswordConfirmation }) => {
  return api.updatePassword(currentPassword, newPassword, newPasswordConfirmation)
}

export const updatePasswordShort = ({ commit }, { newPassword }) => {
  return api.updatePasswordShort(newPassword)
    .then(result => {
      commit('userSetPasswordUpdated')
      return result
    })
    .catch(err => {
      return err
    })
}

export const updateContact = ({}, { first_name, last_name, email }) => {
  return api.updateContact(first_name, last_name, email)
}

export const updateProfileSkip = ({}) => {
  return api.updateProfileSkip()
}

export const updateProfileNeverAsk = ({}) => {
  return api.updateProfileNeverAsk()
}
export const updateTeamName = ({ commit }, { team, name }) => {
  commit('setTeamName', { team, name })
  return api.updateTeamName(team, name)
}

/**
 * Redeems the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export const redeemInvitation = ({}, { invitation }) => {
  return api.redeemInvitation(invitation)
}

/**
 * Rejects the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export const rejectInvitation = ({}, { invitation }) => {
  return api.rejectInvitation(invitation)
}

/**
 * Revokes the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export const revokeInvitation = ({}, { invitation }) => {
  return api.revokeInvitation(invitation)
}
/**
 * Re-sends the given invitation.
 *
 * @param invitation
 * @return {*}
 */
export const resendInvitation = ({}, { invitation }) => {
  return api.resendInvitation(invitation)
}

export const fetchApiMember = ({}, { member }) => {
  return api.fetchApiMember(member)
}

export const setActiveWorkspace = ({ commit, getters }, { workspace }) => {
  let realWorkspace = workspace
  if (!getters.canUserEditProjectTrack && workspace === 'Sequencer') {
    realWorkspace = 'SequencerReadonly'
  }
  commit('setActiveWorkspace', { workspace: realWorkspace })
  // commit('setHashKey', {key: 'w', value: workspace})
  // commit('setHashFromState')
}
