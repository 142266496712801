import store from '../store'

export default [
  {
    path: '/',
    alias: '/',
    name: 'home',
    meta: {
      isAuthenticated: false,
      isAuthBounce: true,
      showProgressBar: false
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthRequired.vue'),
    beforeEnter: (to, from, next) => {
      let authedAndReady = store.getters['auth/isAuthedAndReady']
      if (authedAndReady) {
        if (store.getters['firstPath'] !== null) {
          next(store.getters['firstPath'])
        } else {
          next('/dashboard')
        }
      } else {
        next('/drive-select')
      }
      //  next()
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/Dashboard.vue'),
    meta: {
      showProgressBar: false
    },

    beforeEnter: (to, from, next) => {
      const needsOnboarding = store.getters['doesUserNeedOnboardingPreviz']
      if (needsOnboarding) {
        next({ name: 'user-onboarding' })
        return
      }

      if (store.getters['lastDrivePath'] !== null) {
        next(store.getters['lastDrivePath'])
      }
      next('drive-select')
    }
  },

  {
    path: '/onboarding',
    name: 'user-onboarding',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/UserOnboarding.vue'),
    meta: {
      showProgressBar: false
    },

    beforeEnter: (to, from, next) => {
      if (store.getters['doesUserNeedOnboardingPreviz']) {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
  },

  {
    path: '/drive-select',
    name: 'drive-select',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/DriveSelect.vue'),
    meta: {
      showProgressBar: false
    }
  },

  {
    path: '/d/:uuid',
    name: 'drive-overview',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@views/DriveOverview.vue'),
    meta: {
      canSetAsFirst: true,
      canSetAsLastDrive: true
    },
    beforeEnter: (to, from, next) => {
      store
        .dispatch('project/loadProject', { projectId: to.params.uuid })
        .then(() => {
          let project = store.getters['project/project']

          store.dispatch('assets/loadPrevizInDrive', {
            project: project
          })
          // fetch drive previzes
          next()
        })
    }
  },

  {
    path: '/auth-required',
    name: 'auth-required',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthRequired.vue'),
    meta: {
      showProgressBar: false,
      isAuthenticated: false
    }
  },

  {
    path: '/auth-error',
    name: 'auth-error',
    component: () =>
      import(/* webpackChunkName: "auth" */ '@views/AuthError.vue'),
    meta: {
      showProgressBar: false,
      isAuthenticated: false
    }
  }
]
