import * as api from '@utils/api.js'
import { updateInListByKey } from '@utils/misc.js'

const state = {
  data: [],
  seen: [],

  loaded: false,
  loading: false,
  loadingError: false
}

const getters = {

  loading (state) {
    return state.loading
  },
  loaded (state) {
    return state.loaded
  },
  loadingError (state) {
    return state.loadingError
  },

  thread: (state) => (id) => {
    let found = state.data.filter(row => row.resource_id === id)
    if (found === undefined) return []
    return found
  },

  latestByProject: (state) => (id) => {
    let found = state.data.filter(row => row.project_id === id)
    if (found === undefined) return []

    let sorted = found.sort(function (a, b) {
      let c = new Date(a.created_at)
      let d = new Date(b.created_at)
      return d - c
    })

    return sorted.slice(0, 8)
  }
}

const mutations = {

  setLoading (state) {
    state.loading = true
    state.loadingError = false
  },
  setLoaded (state) {
    state.loading = false
    state.loadingError = false
    state.loaded = true
  },
  setLoadingError (state) {
    state.loading = false
    state.loadingError = true
  },
  addItem (state, item) {
    state.data = updateInListByKey(state.data, 'id', item.id, item)

    if (!state.seen.includes(item.id)) {
      state.seen.push(item.id)
    }
  },

  removeItem (state, item) {
    var index = state.data.findIndex(e => e.id === item.id)
    if (index > -1) state.data.splice(index, 1)

    // Also remove from seen
    var i = state.seen.findIndex(id => id === item.id)
    if (i > -1) state.seen.splice(i, 1)
  }
}

const actions = {

  addItem ({ commit }, { comment }) {
    commit('addItem', comment)
  },

  removeItem ({ commit }, { comment }) {
    commit('removeItem', comment)
  },

  delete ({ dispatch }, { comment }) {
    let originals = comment
    dispatch('removeItem', { comment: comment })

    let uri = 'api/comments/' + comment.id

    return api.rawApiDeleteCall(uri)
      .catch((error) => {
        console.log(originals)
        console.log(error)
      })
  },

  addComment ({ dispatch }, { resourceId, payload }) {
    let uri = 'api/comments?resource_id=' + resourceId

    return api.rawApiPostCall(uri, payload)
      .then(response => {
        dispatch('addItem', { comment: response.data.data })

        return response
      }).catch(err => {
        console.warn('ERROR ', err)
        return false
      })
  },

  loadRecentItems ({ commit, dispatch }, { project }) {
    // Base Api call
    let uri = 'api/comments'
    let options = { params: { project_id: project.id } }

    commit('setLoading')
    return api.rawApiGetCall(uri, options)
      .then(response => {
        dispatch('handleReturn', response.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  loadCommentsForAsset ({ commit, dispatch }, { assetId, force }) {
    if (force === undefined) force = false

    // // Early return check
    // if (!force) {
    //   let find = state.items.find(row => (row.asset_id === assetId))
    //   if (find !== undefined) return find
    // }

    let uri = 'api/comments?resource_id=' + assetId
    let options = { params: { resource_id: assetId } }
    commit('setLoading')

    return api.rawApiGetCall(uri, options)
      .then(response => {
        dispatch('handleReturn', response.data)
      })
      .catch(() => {
        commit('setLoadingError')
      })
  },

  handleReturn ({ commit, dispatch }, data) {
    let dataCleaned = []
    data.data.forEach(row => {
      dataCleaned.push(row.data)
    })

    // let hasMore = false
    // data.pagination.links.forEach(row => {
    //   if (row.rel === 'pagination.next') {
    //     commit('setNextPageLink', row.url)
    //     hasMore = true
    //   }
    //   if (row.rel === 'pagination.previous') {
    //     commit('setPrevPageLink', row.url)
    //   }
    // })

    // let totalRecords = data.pagination.total_count

    // commit('setHasMore', hasMore)
    dataCleaned.forEach(row => {
      dispatch('addItem', { comment: row })
    })
    // commit('setTotalRecords', totalRecords)

    // if (hasMore) {
    //   dispatch('loadNext')
    // } else {
    commit('setLoaded')
    // }
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
